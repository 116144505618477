import React, { useEffect, useState } from 'react'

const useScrollTop = () => {
    const [isTop, setTop] = useState(true);
    useEffect(() => {
      let throttleTimeout;
  
      const handleScroll = () => {
        if (throttleTimeout) {
          return;
        }
        throttleTimeout = setTimeout(() => {
          throttleTimeout = null;
          if (window.scrollY > 0) {
            setTop(false);
          } else {
            setTop(true);
          }
        }, 100);
      };
  
      window.addEventListener('scroll', handleScroll, { passive: true });
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        if (throttleTimeout) {
          clearTimeout(throttleTimeout);
        }
      };
    }, []);
    return isTop;
}

export default useScrollTop
