import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Header from "./components/header";
import Footer from "./components/footer";
import navActions from "./redux/actions/navigation_action";
import loadingActions from "./redux/actions/loading_action";
import { updateMemberRequestAction } from "./redux/actions/member_requests_action";
import { getRequests, getNotifications, recordUserLogs } from "./redux/services/userService";
import { toastPopup } from "./components/helper/toastPopup";
import { UserContext } from "./context/userContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./styles.css"
const App = (props) => {
  // akshay Gondaliya
  const { token, duration, startLoading } = props;
  const userContext = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    let startTime = new Date().getTime();
    let url = window.location.pathname;

    const handleRouteChange = async (location) => {
      if (!duration) return;
      if (location.pathname === url && location.hash !== "") {
        return;
      }

      const capturedDuration = duration;
      startLoading();
      const endTime = new Date().getTime();
      const timeSpent = endTime - startTime;
      const timeSpentInMs = Math.round(timeSpent);
      let obj = { pathname: url, loadingTime: capturedDuration, timeSpent: timeSpentInMs };
      let res = await recordUserLogs(token, obj);

      if (res.redirect) {
        window.location.href = res.page;
      }
      startTime = new Date().getTime();
      url = location.pathname;
    };

    const unlisten = history.listen(handleRouteChange);
    return () => {
      unlisten();
    };
  }, [history, duration]);



  useEffect(() => {
    const unsubscribeHistory = props?.history.listen((location, action) => {
      const getMemberConnectRequest = async () => {
        try {
          const response = await getNotifications(token, { page: 1, limit: 10, type: "all" });
          const popupValue = localStorage.getItem("toastPopupOpen");
          if (parseInt(popupValue) === 1 || popupValue === null) setTimeout(() => localStorage.setItem("toastPopupOpen", 0), 5000);
          const acceptedRequests = response.notifications?.some(
            (notification) => !notification?.isRead && notification.type === "member_request_accept"
          );
          if (
            parseInt(popupValue) === 0 &&
            location.pathname === "/dashboard" &&
            response?.success &&
            response.notifications?.some((notification) => !notification?.isRead)
          ) {
            localStorage.setItem("toastPopupOpen", 1);
            toastPopup(!acceptedRequests ? "Received new memebr request." : "A member has accepted your request.", "info", {}, {});
          }
          const memeberConnectReqAccepted = await getRequests(token, { status: "accepted", type: "sent", limit: 100, page: 0 });
          if (memeberConnectReqAccepted?.requests) {
            props.updateMemberRequest(memeberConnectReqAccepted.requests);
          }
        } catch (error) {
          console.error("Error: Notifications/GET requests API failed.", error);
        }
      };
      getMemberConnectRequest();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div style={{ flexGrow: "1", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>{props.children}</div>
      <Footer />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.user.currentUser,
  duration: state.loading.duration
});

const mapDispatchToProps = (dispatch) => ({
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  updateMemberRequest: (props) => dispatch(updateMemberRequestAction(props)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
