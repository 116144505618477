import React from "react";
import { Input } from "reactstrap";

const Pagination = props => {

    const { page, pages, limits, limit, setLimit, setPage } = props;


    const getPages = () => {
        let buttons = [];
        for (let i = 0; i < pages; i++) buttons.push(i);
        if (pages <= 5) {
            return buttons.map((x, i) => (
                <button
                    key={i}
                    className={`ml-1 btn btn-sm btn-${i === page ? "success" : "danger"}`}
                    onClick={e => {
                        setPage(x);
                    }}
                >
                    {x + 1}
                </button>
            ));
        } else {
            let pageSubset = [];
            if (page < 2) {
                pageSubset = buttons.slice(0, 3);
            } else if (page >= pages - 2) {
                pageSubset = buttons.slice(pages - 3);
            } else {
                pageSubset = buttons.slice(page - 1, page + 2);
            }
            return (
                <div style={{ display: "flex", gap: "3px", alignItems: "baseline" }}>
                    {pageSubset.map((x, i) => (
                        <button
                            key={i}
                            className={`ml-1 btn btn-sm btn-admin-sm btn-${x === page ? "success" : "danger"}`}
                            onClick={e => {
                                setPage(x);
                            }}
                        >
                            {x + 1}
                        </button>
                    ))}
                    {pages - 3 >= page && <><h3>...</h3>
                        <button
                            className={`ml-1 btn btn-sm btn-admin-sm btn-${pages === page ? "success" : "danger"}`}
                            onClick={e => {
                                setPage(pages - 1);
                            }}
                        >
                            {pages}
                        </button></>}
                </div>
            );
        }
    };



    return (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    {limit && <Input
                        type="select"
                        name="limit"
                        id="limit"
                        value={limit}
                        className="col-3"
                        onChange={e => {
                            setLimit(e.target.value);
                        }}
                    >
                        {(limits && limits.length > 0) ? <>
                            {limits.map((x, i) => {
                                return (
                                    <option key={i} value={x}>{x}</option>
                                )
                            })}
                        </> : <>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                        </>}
                    </Input>}
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="pull-right">
                        {getPages()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination;
