import loadingConstant from "../constants/loading_constant";
const initialState = {
  isLoading: true,
  startTime: new Date().getTime(),
  duration: null,
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case loadingConstant.LOADING_START:
      return {
        ...state,
        isLoading: true,
        startTime: new Date().getTime(),
        duration: null,
      };
    case loadingConstant.LOADING_END:
      const startTime = state.startTime;
      const endTime = new Date().getTime();
      const duration = state.duration ? state.duration : endTime - startTime;

      return {
        ...state,
        isLoading: false,
        startTime: null,
        duration
      };
    default:
      return state;
  }
};

export default loadingReducer;
