/* eslint-disable import/no-anonymous-default-export */
import { api } from "./apiService";

export const getData = () => api().get("/app/getData").then(handleResponse);

export const getReligions = () => api().get("/app/getReligions").then(handleResponse);

export const getCountries = () => api().get("/app/get-countries").then(handleResponse);

export const createQueries = (data) => api().post("/app/query",data).then(handleResponse);

export const getStates = (token, country) =>
  api(token)
    .get("/app/get-states/" + country)
    .then(handleResponse);

export const getCities = (token, state) =>
  api(token)
    .get("/app/get-cities/" + state)
    .then(handleResponse);

export const getGothras = (token, caste) =>
  api(token)
    .get("/app/get-gotras-subCastes/" + caste)
    .then(handleResponse);

const handleResponse = (response) => {
  let data = response.data;

  
  if (response.status === 404 || data.status === 400 || response.status !== 200 || data.code !== 5) {
    let error = data.error ? data.error : data.message;
    return error;
    //return new Promise(error);
  }
  return data;
};

export default {
  getData,
  getReligions,
};
