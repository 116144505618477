import member_requests_constant from "../constants/member_requests_constant";

const initialState = {
  data: [],
};

function getMemberRequestsData(state = initialState, action) {
  switch (action.type) {
    case member_requests_constant.SET_REQUESTS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default getMemberRequestsData;
