import navConstant from "../constants/navigation_constant";

const navState = {
  alert: {
    isOpen: false,
    message: "",
  },
  loader: {
    isOpen: false,
  },
};

function nav(state = navState, action) {
  switch (action.type) {
    case navConstant.FETCH_SUCCESS:
      return {
        ...state,
        alert: {
          isOpen: true,
          message: action.payload.message ? action.payload.message : action.payload,
        },
      };
    case navConstant.FETCH_FAILURE:
      return {
        ...state,
        alert: {
          isOpen: true,
          message: action.payload.message ? action.payload.message : action.payload,
        },
        loader: {
          isOpen: false,
        },
      };
    case navConstant.FETCH_CLEAR:
      return {
        ...state,
        alert: {
          isOpen: false,
          message: "",
        },
        loader: {
          isOpen: false,
        },
      };
    case navConstant.FETCH_REQUEST:
      return {
        ...state,
        loader: {
          isOpen: true,
        },
      };
    case navConstant.TOGGLE_ALERT:
      return {
        ...state,
        alert: {
          isOpen: action.payload.isOpen,
          message: action.payload.message,
        },
        loader: {
          isOpen: false,
        },
      };
    default:
      return state;
  }
}

export default nav;
