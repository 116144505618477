import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { thunk } from "redux-thunk";
//import promiseMiddleware from 'redux-promise';
import Routes from "./routes/routes";
import reducers from "./redux/reducers/";
import { ToastContainer } from 'react-toastify';

import "./assets/css/bootstrap.min.css";
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js'
import $ from 'jquery';
// import 'bootstrap/dist/js/bootstrap.min.js'
import "./assets/css/datepicker3.css";
// // import "./assets/css/icon-font.min.css";
// // import "./assets/css/icofont.min.css";
import "./assets/other/tab-page.css"
import "./assets/css/lightgallery.min.css";
import "./assets/css/plugins.css";
import "./assets/css/jqvmap.min.css";
import "./assets/owlslider/owl.carousel.css";
import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "../node_modules/select2/dist/css/select2.min.css";
import "../node_modules/switchery/switchery.css";
//import "../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./assets/other/contact-app-page.css";
import "./assets/login-signup-forgot/style.min.css";
import "./assets/login-signup-forgot/login-register-lock.css";
import "./assets/css/style.css";
import "./assets/css/stylemy.css";
import "./assets/DCstylesheet.css";
import 'cropperjs/dist/cropper.css';
import { createRoot } from "react-dom/client";

window.jQuery = $;

//const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStore(reducers, applyMiddleware(thunk, logger));

createRoot(document.getElementById('root')).render(<Provider store={store}>
  <BrowserRouter>
    <Routes />
    <ToastContainer />
  </BrowserRouter>
</Provider>)
