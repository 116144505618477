import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import navActions from "../../redux/actions/navigation_action";
import { userTreeView } from "../../redux/services/userService";
import { Tree, TreeNode } from "react-organizational-chart";
import styled from "styled-components";
import { getTreeData } from "../../redux/services/userService";
import { CONFIG } from "../../constants/general";
import Loader from "../loader";
import { Col, Form, Row } from "react-bootstrap";
import loadingActions from "../../redux/actions/loading_action";

const ViewFamilyTree = (props) => {
  const { token, loading, startLoading, endLoading, currentUser, relations, failure } = props;
  const [treeData, setTreeData] = useState();
  const [hasOverflow, setHasOverflow] = useState(false);
  const [type, setType] = useState("father");
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const StyledNode = styled.div`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid red;
  `;

  const fnGetTressData = () => {
    userTreeView(token, { id: props.match.params.id, type: type }).then((result) => {
      if (result.code === 5) {
        setTreeData(result.data);
        setUser(result.user)
        setTimeout(()=>{
          if (loading) endLoading();
        },0)
      } else {
        setError(result.error)
        setTimeout(()=>{
          if (loading) endLoading();
        },0)
      }
    });
  };

  // const TreeNodeDiv = ({ ...x }) => {
  //   return <TreeNode label={<StyledNode>{x.one.name + " / " + x.two.name}</StyledNode>}>{x.three && <TreeNodeDiv {...x.three} />}</TreeNode>;
  // };

  useEffect(() => {
    fnGetTressData();
    const containerElement = document.querySelector('.theme-container');
    if (containerElement) {
      containerElement.style.maxWidth = '98%';
    }
  }, [type]);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current && !loading) {
      const element = scrollContainerRef.current;
      setHasOverflow(element.scrollWidth > element.clientWidth);
      element.scrollLeft = (element.scrollWidth - element.clientWidth) / 2;
    }
  }, [scrollContainerRef.current?.scrollWidth, scrollContainerRef.current?.clientWidth, loading]);

  // const filterData = (data) => {
  //   let level1 = []
  //   let level2 = []
  //   let level3 = []
  //   data && data.map(rel => {
  //     if (TREE['level1'].indexOf(rel.relation) !== -1) {
  //       level1.push(rel)
  //     }
  //     if (TREE['level2'].indexOf(rel.relation) !== -1) {
  //       level2.push(rel)
  //     }
  //     if (TREE['level3'].indexOf(rel.relation) !== -1) {
  //       level3.push(rel)
  //     }
  //   })
  //   setLevel1(level1)
  //   setLevel2(level2)
  //   setLevel3(level3)
  // }

  const getText = (obj) => {
    let text = "N/A";
    if (obj.to_name && obj?.spouseDetails?.length > 0) text = obj.to_name + " / " + obj.spouseDetails[0].to_name;
    else if (obj.to_name) text = obj.to_name;
    return text;
  };

  const getRelation = (obj) => {
    let text = "N/A";
    let memberFatherFrom = obj?.relationDetails?.memberFrom.toLowerCase() != user.basicDetails.name.toLowerCase() ? obj?.relationDetails?.memberFrom : "";
    let memberMotherFrom = obj?.spouseDetails[0]?.relationDetails?.memberFrom.toLowerCase() != user.basicDetails.name.toLowerCase() ? obj?.spouseDetails[0]?.relationDetails?.memberFrom : "";
    obj['father'] = { _id: obj.relationDetails.relation_id }
    obj['mother'] = { _id: obj.spouseDetails.length > 0 ? obj.spouseDetails[0].relationDetails.relation_id : null }
    if (obj?.father) obj["father"]['relation'] = relations.filter((x) => String(x._id) == String(obj.father._id))[0]
    if (obj?.mother) obj["mother"]['relation'] = relations.filter((x) => String(x._id) == String(obj.mother._id))[0]
    if (obj.father.relation && obj.mother.relation) text = memberFatherFrom + " " + obj.father.relation[currentUser.basicDetails.motherTongue.mothertongue] + " / " + memberMotherFrom + " " + obj.mother?.relation[currentUser.basicDetails.motherTongue.mothertongue];
    else if (obj.father.relation || obj.mother.relation) text = memberFatherFrom + " " + obj?.father.relation[currentUser.basicDetails.motherTongue.mothertongue] || memberMotherFrom + " " + obj?.mother.relation[currentUser.basicDetails.motherTongue.mothertongue]
    return text;
  };


  const getInitial = (obj, keyName) => {
    let name = keyName == 'father' ? obj.to_name : (obj?.spouseDetails.length > 0 ? obj.spouseDetails[0].to_name : "") || ""
    const totalLength = name.split(" ").length;
    if (totalLength > 1) {
      return (name.split(" ")[0].substring(0, 1) + name.split(" ")[totalLength - 1].substring(0, 1)).toUpperCase()
    }
    return name.substring(0, 1).toUpperCase();
  }

  const getIconWithName = (obj, keyName) => {
    let src = keyName == "father" ? obj?.userDetails?.profilePicture : obj.spouseDetails[0]?.userDetails?.profilePicture
    return <div style={{
      display: "inline-block",
      backgroundColor: "#00d53b",
      margin: "10px",
      width: "100px",
      height: "100px",
      borderRadius: "50%"
    }}>
      <div style={{
        color: "white",
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        textDecoration: "none",
        height: "100px",
        width: "100px",
        fontSize: "30px"
      }}>
        {
          (src && !(src == 'male.png' || src == "female.png")) ? <img style={{ width: "inherit", height: "inherit", borderRadius: "50%" }} src={CONFIG.REACT_APP_UPLOADS + '/' + src} /> : getInitial(obj, keyName)
        }
      </div>
    </div>
  }

  const getImageDetails = (obj) => {
    return (<div style={{
      display: "flex",
      justifyContent: "space-evenly"
    }}>
      {(obj?.to_name && obj.spouseDetails?.length > 0) ? (getIconWithName(obj, "father"))
        : (obj?.to_name) ? getIconWithName(obj, "father") : getIconWithName(obj, "mother")}
      {(obj?.to_name && obj?.spouseDetails?.length > 0) ? getIconWithName(obj, "mother") : null}
    </div>)
  }

  const showTreeRecursive = (array) => {
    array.sort((a, b) => {
      const dateA = new Date(a.userDetails?.DOB);
      const dateB = new Date(b.userDetails?.DOB);
      return dateA - dateB;
    });
    return array.map((tree, idx) => {
      return (
        <TreeNode key={idx} label={<StyledNode><div>
          {getImageDetails(tree)}
          {getText(tree)}
          <div>
            {getRelation(tree)}
          </div>
        </div></StyledNode>}>
          {tree?.children ? showTreeRecursive(tree.children) : null}
        </TreeNode>
      );
    });
  };

  const filterTreeData = (data) => (
    <Tree lineWidth={"2px"} lineColor={"green"} lineBorderRadius={"10px"} label={<StyledNode><div>
      {getImageDetails(data[0])}
      {getText(data[0])}
      <div>
        {getRelation(data[0])}
      </div>
    </div></StyledNode>}>
      {data[0].children ? showTreeRecursive(data[0].children) : null}
    </Tree>
  );

  return (
    <div>
      {error ? <div className="theme-container container mt-170 mb-50">{error}</div> : <div className="theme-container container mt-170 mb-50">
        {!loading && <section className="breadcrumb-bg p-0">
          <div className="theme-container container ">
            <div className="site-breadcumb white-clr">
              <div className="text-white ">
                <div className="row py-2">
                  <div className="img-fluid profile-header-img ribbon-wrapper w-20">
                    <img
                      src={`${CONFIG.REACT_APP_UPLOADS}/${user.profilePicture}`}
                      className="img-circle"
                      width="100"
                      height="100"
                      alt="profileImg"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="profile-header-info align-content-center w-75">
                    <h4 className="card-title text-white m-b-20">
                      <span>{user.basicDetails.name} </span>
                    </h4>
                    {/* <h6 className="card-subtitle text-white m-b-15">@jamesandre</h6> */}
                    <h6 className="card-subtitle text-white m-b-10">Profile Created for {user.basicDetails.profileFor}</h6>
                    <p className="text-white">{user.aboutMe && user.aboutMe}</p>
                    <Form.Group as={Row}>
                      <Form.Label column sm="1">
                        Tree :
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control name="state" as="select" value={type} onChange={(e) => setType(e.target.value)}>
                          <option value={"father"}>Father</option>
                          <option value={"mother"}>Mother</option>
                          {user.gender == "female" && <option value={"husband"}>Husband</option>}
                          {user.gender == "male" && <option value={"wife"}>Wife</option>}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>}
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="scrollContainer" ref={scrollContainerRef} style={{ overflowX: hasOverflow ? 'scroll' : 'hidden',paddingBlock:"5px" }}>
              {treeData?.length === 1 ? (
                filterTreeData(treeData)
              ) : treeData?.length > 1 ? (
                <Tree lineWidth={"2px"} lineColor={"green"} lineBorderRadius={"10px"} label={<StyledNode>{""}</StyledNode>}>
                  {showTreeRecursive(treeData)}
                </Tree>
              ) : null}
            </div>
          </>
        )}

      </div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.user.currentUser,
  userId: state.auth.userId,
  relations: state.data.relations,
  loading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewFamilyTree));
