
export const getAge = (dateString, flag) => {
  var now = new Date();
  var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();
  var dob = new Date(dateString.substring(6, 10), dateString.substring(0, 2) - 1, dateString.substring(3, 5));
  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = "";
  var monthString = "";
  var dayString = "";

  var yearAge = yearNow - yearDob;
  var monthAge;
  var dateAge;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 1) yearString = " years";
  else yearString = " year";
  if (age.months > 1) monthString = " months";
  else monthString = " month";
  if (age.days > 1) dayString = " days";
  else dayString = " day";

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString = age.years + yearString + ", " + age.months + monthString + ", and " + age.days + dayString + " old.";
  else if (age.years === 0 && age.months === 0 && age.days > 0) ageString = "Only " + age.days + dayString + " old!";
  else if (age.years > 0 && age.months === 0 && age.days === 0) ageString = age.years + yearString + " old. Happy Birthday!!";
  else if (age.years > 0 && age.months > 0 && age.days === 0) ageString = age.years + yearString + " and " + age.months + monthString + " old.";
  else if (age.years === 0 && age.months > 0 && age.days > 0) ageString = age.months + monthString + " and " + age.days + dayString + " old.";
  else if (age.years > 0 && age.months === 0 && age.days > 0) ageString = age.years + yearString + " and " + age.days + dayString + " old.";
  else if (age.years === 0 && age.months > 0 && age.days === 0) ageString = age.months + monthString + " old.";
  else ageString = "Oops! Could not calculate age!";
  return flag ? ageString.split(",")[0] : ageString;
};


export const getAds = (array, ads) => {
  let findAds = ads.filter(item => array.includes(item.position)) || []
  if (findAds.length === 0) return { htmlContent: "", remainingAds: ads };

  let random = Math.floor(Math.random() * findAds.length);
  let selectedAd = findAds[random];
  let remainingAds = ads.filter(x => x._id !== selectedAd._id);
  let htmlContent = selectedAd.htmlContent || "";

  return { htmlContent, remainingAds };
};
