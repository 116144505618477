import axios from "axios";
import { CONFIG } from "../../constants/general";

const BACKEND = CONFIG.REACT_APP_ENDPOINT+'/api/' || "http://localhost:3001/api/";
export const api = (token) => {
  // if(token != undefined)
    if (token)
      return axios.create({
        baseURL: BACKEND,
        headers: { 
          'Cache-Control': 'no-cache',
          jwt: token
        },
      });
    else
      return axios.create({
        baseURL: BACKEND,
        headers: { 
          'Cache-Control': 'no-cache'
        },
      });
};
