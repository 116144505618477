import React, { useEffect, useState } from 'react'
import { getFilterForMatch, getFilteredMatch, getNotifications, getPreferedYouMatches, matchUser } from '../../redux/services/userService'
import navActions from "../../redux/actions/navigation_action";
import { connect } from "react-redux";
import Pagination from './Pagination';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { CONFIG } from '../../constants/general';
import Loader from '../loader';
import loadingActions from "../../redux/actions/loading_action";


const MatchRecommendation = (props) => {
  const { token, currentUser, loading, startLoading, endLoading, success, failure } = props;
  const [matchUsers, setMatchUsers] = useState([])
  const [matchYouUsers, setMatchYouUsers] = useState([])
  const age = Math.floor((new Date() - new Date(currentUser.DOB).getTime()) / 3.15576e10)

  let getPreferedMatch = async () => {
    let searchFilter = {
      page: 0,
      limit: 4,
      gender: currentUser.gender == "male" ? "female" : "male",
    }
    if (currentUser.religionDetails?.gotra) searchFilter['gotra'] = currentUser.religionDetails.gotra
    if (currentUser.preference?.ageFrom) searchFilter['age'] = { from: currentUser.preference.ageFrom, to: currentUser.preference.ageTo }
    if (currentUser.preference?.annualIncome) searchFilter['AnualIncome'] = [currentUser.preference.annualIncome]
    if (currentUser.preference?.caste?._id) searchFilter['caste'] = [currentUser.preference?.caste?._id]
    if (currentUser.preference?.dosh) searchFilter['dosh'] = [currentUser.preference?.dosh]
    if (currentUser.preference?.drinkingHabit) searchFilter['drinkingHabit'] = [currentUser.preference?.drinkingHabit]
    if (currentUser.preference?.eatingHabit) searchFilter['eatingHabit'] = [currentUser.preference?.eatingHabit]
    if (currentUser.preference?.heightFeetFrom) searchFilter['height'] = { from: currentUser.preference?.heightFeetFrom, to: currentUser.preference?.heightFeetTo }
    if (currentUser.preference?.maritalStatus) searchFilter['maratialStatus'] = [currentUser.preference?.maritalStatus]
    if (currentUser.preference?.motherTongue?._id) searchFilter['motherTongue'] = [currentUser.preference?.motherTongue?._id]
    if (currentUser.preference?.occupation) searchFilter['occupation'] = [currentUser.preference?.occupation]
    if (currentUser.preference?.religion?._id) searchFilter['religion'] = [currentUser.preference?.religion?._id]
    if (currentUser.preference?.smokingHabit) searchFilter['smokingHabit'] = [currentUser.preference?.smokingHabit]
    if (currentUser.preference?.fatherSide) searchFilter['fatherGen'] = currentUser.preference?.fatherSide
    if (currentUser.preference?.motherSide) searchFilter['motherGen'] = currentUser.preference?.motherSide
    let result = await getFilteredMatch(token, searchFilter, currentUser._id)
    let users = result.match.map((i) => {
      return ({
        _id: i._id,
        name: i.name,
        details: `${i.age ? i.age + ' ' : ''}${i?.height?.feet ? i.height.feet + "'" : ''}${i?.height?.inch ? i.height.inch + '"' : ''} ${i.religion ? i.religion + ' ' : ''}${i.caste ? i.caste + ' ' : ''}${i.education ? i.education + ' ' : ''}${i.occupation ? i.occupation + ' ' : ''}${i.city ? i.city + ' ' : ''}${i.state ? i.state + ' ' : ''}${i.country ? i.country : ''}`,
        timestamp: i.lastSeen,
        imageSrc: `${CONFIG.REACT_APP_UPLOADS}/${i.profilePicture}`
      })
    })
    setMatchUsers(users)
  }
  let getPreferedYou = async () => {
    let result = await getPreferedYouMatches(token, { page: 1, limit: 4 })
    let users = result.findPreferedYouUsers?.map((i) => {
      return ({
        _id: i._id,
        name: i.name,
        details: `${i.age ? i.age + ' ' : ''}${i?.height?.feet ? i.height.feet + "'" : ''}${i?.height?.inch ? i.height.inch + '"' : ''} ${i.religion ? i.religion + ' ' : ''}${i.caste ? i.caste + ' ' : ''}${i.education ? i.education + ' ' : ''}${i.occupation ? i.occupation + ' ' : ''}${i.city ? i.city + ' ' : ''}${i.state ? i.state + ' ' : ''}${i.country ? i.country : ''}`,
        timestamp: i.lastSeen,
        imageSrc: `${CONFIG.REACT_APP_UPLOADS}/${i.profilePicture}`
      })
    })
    setMatchYouUsers(users)
  }
  let sendIntrest = async (id) => {
    matchUser(token, { id: id }, currentUser._id)
      .then((data) => {
        if (data.code == 5) {
          success(data.message);
          getPreferedMatch()
          getPreferedYou()
        } else {
          failure(data.error)
        }
      })
      .catch((error) => {
        failure(error);
      });
  }

  const checkEligibilty = () => {
    if (age < 18) {
      return (<>Your account is minor your age should be grater then 18.</>)
    } else if (!currentUser.isEmailVerified) {
      return (<>Email should be verified for profile match</>)
    } else if (!currentUser.religionDetails.gotra) {
      return (<>Gothra should be added</>)
    } else if (Object.keys(currentUser.preference).length == 0) {
      return (<>Preferance should be added to personlize your list</>)
    } else {
      return false
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getPreferedMatch();
        await getPreferedYou();
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        if (loading) endLoading();
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const ProfileList = ({ title, subtitle, buttonLabel, items }) => (
    <div className="col-lg-12 col-xlg-12 col-md-12 form-horizontal form-material box-shadow05 p-0">
      <div className="card bordernone m-b-0 p-b-25">
        <div className="prlist-heading m-b-15">
          <div className="float-left">
            <h3>{title} <span>({items.length})</span></h3>
            <p>{subtitle}</p>
          </div>
          <div className="float-right">
          </div>
        </div>
        <div className="d-flex nav-1 cus-prmview cus-profil-list-slides align-self-center">
          {items.length > 0 ? items.map((item, index) => (
            <div key={index} className="item">
              <div className="random-prod cus-rendom-prod d-flex flex-column align-items-center">
                <div className="random-img">
                  <img alt="" src={item.imageSrc} />
                </div>
                <div className="random-text">
                  <h5 className="title-1 no-margin"> <a><strong>{item.name}</strong> </a> </h5>
                  <p>{item.details}</p>
                  <p><span className="badge badge-pill badge-success text-white m-b-10">{item.timestamp}</span></p>
                  <a href={`/viewProfile/${item._id}`} target="_blank" className="btn waves-effect waves-light btn-xs btn-info p-l-10 p-r-10 p-t-5 p-b-5 ">View profile</a>
                  <button onClick={() => { sendIntrest(item._id) }} className="btn waves-effect waves-light btn-xs btn-info p-l-10 p-r-10 p-t-5 p-b-5 ">Send interest</button>
                </div>
              </div>
            </div>
          )) : <>No Profile Found With Your Preferances Go To Profile<a href='/newMatches'>&nbsp;Match Page&nbsp;</a>to View Profiles</>}
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <section className="breadcrumb-bg">
        <div className="theme-container container ">
          <div className="site-breadcumb white-clr">
            <h2 className="section-title"> <span className="light-font">Profiles</span></h2>
            <ol className="breadcrumb breadcrumb-menubar">
              <li> <a> Home </a> Profiles </li>
            </ol>
          </div>
        </div>
      </section>

      <div className="theme-container container m-t-50 m-b-50">
        <div className="row">
          {loading ? (
            <Loader />
          ) : (<>
            {checkEligibilty()}
            {!checkEligibilty() && <>
              <ProfileList
                title="Members you may like"
                subtitle="Members who match many of your Preferences"
                buttonLabel="MORE"
                items={matchUsers}
              />

              <ProfileList
                title="Members looking for you"
                subtitle="Members looking for matches like you"
                buttonLabel="MORE"
                items={matchYouUsers}
              />
            </>
            }
          </>)}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.user.currentUser,
  loading: state.loading.isLoading
});
const mapDispatchToProps = (dispatch) => ({
  // setUserData: (user) => dispatch(setUserData(user)),
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchRecommendation)
