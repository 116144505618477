/* eslint-disable import/no-anonymous-default-export */
import { api } from "./apiService";

export const login = (user) => api().post("/auth/login", user).then(handleResponse);

export const checkEmailExist = (data) => api().post("/auth/checkEmail", data).then(handleResponse);

export const checkEmailExistAndSendMail = (data) => api().post("/auth/checkEmail/sendMail", data).then(handleResponse);

export const forgotPassword = (data) => api().post("/auth/forgotPassword", data).then(handleResponse);

export const checkLogin = (token) => api(token).get("/auth/checkLogin").then(handleResponse);

export const checkAdminLogin = (token) => api(token).get("/auth/checkAdminLogin").then(handleResponse);

export const adminLogin = (user) => api().post("/auth/adminLogin", user).then(handleResponse);

export const logout = async () => await api().post("/auth/logout").then(handleResponse);


const handleResponse = (response) => {
  let data = response.data;
  if (response.status === 404 || response.status === 401 || data.status === 400 ||  data.status === 500 || response.status !== 200 || data.code !== 5) {
    let error = data.error ? data.error : data.message;
    return error;
    // return new Promise(error);
  }
  return data;
};

export default { login, checkLogin, logout, checkEmailExist, checkEmailExistAndSendMail, forgotPassword };
