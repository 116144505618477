import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { userId, userRole } = rest;
  // let family_token = JSON.parse(localStorage.getItem("family_token"));
  return (
    <Route
      {...rest}
      render={(props) =>
        // family_token.userId && family_token.userRole === 1 ? (
        //   <Component {...props} />
        // ) : (
        //   <Redirect to={{ pathname: "/adminlogin", state: { from: props.location } }} />
        // )
        userId && userRole === 1 ? <Component {...props} /> : <Redirect to={{ pathname: "/adminlogin", state: { from: props.location } }} />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps, null)(AdminRoute);
