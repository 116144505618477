import { combineReducers } from "redux";
import auth from "./auth_reducer";
import data from "./data_reducer";
import nav from "./nav_reducer";
import user from "./user_reducer";
import loading from "./loading_reducer";
import getMemberRequestsData from "./member_requests_reducer";

const rootReducer = combineReducers({ auth, data, loading, nav, user, getMemberRequestsData });

export default rootReducer;
