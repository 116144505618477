import dataConstant from "../constants/data_constants";

const dataState = {
  castes: [],
  countries: [],
  motherToungues: [],
  religions: [],
  cities: [],
  states: [],
  gothras: [],
  subcastes: [],
  relations: [],
  ads: [],
  lifeStyle: {},
};

function data(state = dataState, action) {
  switch (action.type) {
    case dataConstant.SET_DATA:
      return {
        ...state,
        castes: action.payload._castes,
        religions: action.payload._religions,
        motherToungues: action.payload._mothertoungues,
        relations: action.payload._relations,
        ads: action.payload._ads,
        lifeStyle: action.payload._lifeStyle
        // countries: action.payload.countries,
        // states: action.payload.states,
        // cities: action.payload.cities,
        // subcastes: action.payload.subcastes,
        // gothras: action.payload.gothras,
      };
    case dataConstant.SET_RELIGIONS:
      return {
        ...state,
        religions: action.payload,
      };
    default:
      return state;
  }
}

export default data;
