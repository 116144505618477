import { toast, Zoom, Bounce, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastPopup = (message, type, attributes = {}, mixedAttributes = {}, transition) => {
  const defaultAttributes = {
    position: "bottom-left",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Zoom,
  };

  const addTransition = (name) => {
    switch (name) {
      case "bounce":
        return Bounce;
      case "flip":
        return Flip;
      default:
        return Zoom;
    }
  };

  switch (type) {
    case "success": {
      toast.success(
        message,
        Object.keys(attributes)?.length
          ? { ...attributes, transition: addTransition(transition) }
          : Object.keys(mixedAttributes)?.length
          ? { ...defaultAttributes, ...mixedAttributes, transition: addTransition(transition) }
          : { ...defaultAttributes, transition: addTransition(transition) }
      );
      break;
    }
    default:
      toast.info(
        message,
        Object.keys(attributes)?.length
          ? { ...attributes, transition: addTransition(transition) }
          : Object.keys(mixedAttributes)?.length
          ? { ...defaultAttributes, ...mixedAttributes, transition: addTransition(transition) }
          : { ...defaultAttributes, transition: addTransition(transition) }
      );
  }
};
