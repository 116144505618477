import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import App from "../app";

const UserRoute = ({ component: Component, ...rest }) => {
  const { userId, userRole } = rest;
  // let family_token = JSON.parse(localStorage.getItem("family_token"));
  return (
    <Route
      {...rest}
      render={(props) =>
        userId && userRole === 2 ? (
          <App {...props}>
            <Component {...props} />
          </App>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps, null)(UserRoute);
