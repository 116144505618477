import React, { useEffect, useRef, useState } from 'react'
import { getFilterForMatch, getFilteredMatch, getMatchChildren, matchUser, shortlistUser } from '../../redux/services/userService'
import navActions from "../../redux/actions/navigation_action";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from './Pagination';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { CONFIG } from '../../constants/general';
import Loader from '../loader';
import "../../styles.css"
import loadingActions from "../../redux/actions/loading_action";
import navigation_action from '../../redux/actions/navigation_action';
import { Form } from 'react-bootstrap';

const FamilyFinding = () => {
  const currentUser = useSelector(state => state.user.currentUser)
  const loading = useSelector(state => state.loading.isLoading)
  const token = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const failure = (x) => dispatch(navigation_action.fetch_failure(x))
  const success = (x) => dispatch(navigation_action.fetch_success(x))
  const endLoading = (x) => dispatch(loadingActions.loadingEnd())
  const [searchfilter, setSearchFilters] = useState({ page: 0, active: false, limit: 10, gotra: currentUser.religionDetails.gotra, fatherGen: currentUser.preference.fatherSide || 3, motherGen: currentUser.preference.motherSide || 3, manglik: [], haveChildren: false, maratialStatus: [], motherTongue: [], caste: [], star: [], education: [], employedIn: [], occupation: [], AnualIncome: [], country: [], citizenship: [], physicalStatus: [], complexion: [], bodyType: [], eatingHabit: [], familyValue: [], familyType: [], familyStatus: [], profileFor: [] })
  const [filter, setFilters] = useState({})
  const [children, setChildren] = useState([])
  const [selected, setSelected] = useState("")
  const [expanded, setExpanded] = useState([])
  const [data, setData] = useState({})
  const imageURL = CONFIG.REACT_APP_UPLOADS + "/"
  const [isFixed, setIsFixed] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const containerRect = containerRef.current?.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (containerRect?.bottom <= viewportHeight) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    findMember()
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const matchAccount = (id) => {
    matchUser(token, { id }, selected)
      .then((data) => {
        if (data.code == 5) {
          success(data.message);
          GetFilteredMatches(searchfilter)
        } else {
          failure(data.error)
        }
      })
      .catch((error) => {
        failure(error);
      });
  }

  const getFilters = () => {
    getFilterForMatch(token).then((data) => {
      setFilters({ active: data.activeUser, filter: data.filter })
    }).catch((error) => {
      console.log(error, "Failed to get all filters")
    })
  }
  useEffect(() => {
    getFilters()
  }, [])
  const handleUpdate = (name, value) => {
    setSearchFilters((prevSearchFilter) => ({
      ...prevSearchFilter,
      [name]: value,
    }));
  };

  const GetFilteredMatches = async (filter) => {
    let findSelected = children.find(x => x._id == selected)
    console.log(findSelected,filter)
    filter["gender"] = findSelected.gender == "male" ? "female" : "male"
    let age = validateInputs(filter?.age?.from, filter?.age?.to, "age")
    let height = validateInputs(filter?.height?.from, filter?.height?.to, "height")
    if (age && height) {
      const getMatches = await getFilteredMatch(token, filter, selected)
      setData(getMatches)
    } else {
      failure({ message: "Enter Valid Details." })
    }
  }

  const validateInputs = (from, to, topic) => {
    if (from && to) {
      if (from > to) {
        return false
      }
    }
    if (topic == "age" && from < 18) {
      return false
    }
    return true
  }

  const handleExpand = (e) => {
    if (expanded.includes(e)) {
      setExpanded([...expanded.filter(x => x != e)])
    } else {
      setExpanded([...expanded, e])
    }
  }

  const findMember = async () => {
    try {
      const result = await getMatchChildren(token, currentUser._id)
      setSelected(result.childrens[0]._id)
      setChildren(result.childrens)
    } catch (error) {
      console.log(error)
    }
  }

  const checkEligibilty = () => {
    if (!selected) {
      return <>Select member to view more</>
    }
    let findMember = children.find(x => x._id == selected)
    const age = Math.floor((new Date() - new Date(findMember.DOB).getTime()) / 3.15576e10)
    if (age < 18) {
      return (<>Your age should be grater then 18.</>)
    } else if (!findMember.isEmailVerified) {
      return (<>Email should be verified for profile match</>)
    } else if (!findMember.religionDetails.gotra) {
      return (<>Gothra should be added</>)
    } else if (Object.keys(findMember.preference).length == 0) {
      return (<>Preferance should be added to personlize your list</>)
    } else {
      return false
    }
  }

  useEffect(() => {
    if (selected) {
      checkEligibilty()
      GetFilteredMatches(searchfilter);
    }
    if (loading) endLoading();
  }, [selected, searchfilter.page, searchfilter.limit])

  return (
    <>
      <section className="breadcrumb-bg">
        <div className="theme-container container ">
          <div className="site-breadcumb white-clr">
            <h2 className="section-title"> <span className="light-font">Search Results ({data.total}) </span></h2>
            <ol className="breadcrumb breadcrumb-menubar">
              <li> <a> Home </a> Search </li>
            </ol>
          </div>
        </div>
      </section>
      <div>
        <div className="theme-container container m-t-50 m-b-50">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex box-shadow05 align-items-center">
                <div className='col-md-2'>
                  Select Member:
                </div>
                <div className='col-md-4 mb-2'>
                  <Form.Control as="select" className="mr-sm-2" name="maritalStatus" value={selected} onChange={(e) => { setSelected(e.target.value) }}>
                    {
                      children.map(x => {
                        return (
                          <option value={x._id}>{x.basicDetails.name}</option>
                        )
                      })
                    }
                  </Form.Control>
                </div>
              </div>
            </div>
            {checkEligibilty()}
            {!checkEligibilty() && <div className='d-flex mt-2'>
              <div className="col-lg-4 col-xlg-3 col-md-5 sidebar-right">
                <div id="accordion2" role="tablist" className="minimal-faq" aria-multiselectable="true">
                  <div className="card ">
                    <div className="card-header" role="tab" id="headingOne11">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseOne11") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#accordion2" onClick={() => handleExpand("collapseOne11")} aria-expanded="false" aria-controls="collapseOne11">
                          Active
                          <span className="text-right float-right">
                            {expanded.includes("collapseOne11") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseOne11") && <div id="collapseOne11" className="collapse show" role="tabpanel" aria-labelledby="headingOne11">
                      <div className="card-body">
                        <div className="custom-control custom-checkbox col-md-12">
                          <input name="active" onChange={(e) => handleUpdate(e.target.name, e.target.checked)} value={searchfilter?.active} type="checkbox" className="custom-control-input" id="wthphic" />
                          <label className="custom-control-label" htmlFor="wthphic">Online Users ({filter?.active})</label>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo22">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("fatherGen") ? "show" : "collapsed"} link`} onClick={() => handleExpand("fatherGen")}>
                          Father Side Generation
                          <span className="text-right float-right">
                            {expanded.includes("fatherGen") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("fatherGen") && <div id="fatherGen" className="collapse show" role="tabpanel">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          <div className="">
                            <select
                              id="numberSelect"
                              className="form-control"
                              value={searchfilter.fatherGen}
                              onChange={(e) => handleUpdate("fatherGen", e.target.value)}
                            >
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo22">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("motherGen") ? "show" : "collapsed"} link`} onClick={() => handleExpand("motherGen")}>
                          Mother Side Generation
                          <span className="text-right float-right">
                            {expanded.includes("motherGen") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("motherGen") && <div id="fatherGen" className="collapse show" role="tabpanel">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          <div className="">
                            <select
                              id="numberSelect"
                              className="form-control"
                              value={searchfilter.motherGen}
                              onChange={(e) => handleUpdate("motherGen", e.target.value)}
                            >
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo22">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseTwo22") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#accordion2" onClick={() => handleExpand("collapseTwo22")} aria-expanded="false" aria-controls="collapseTwo22">
                          Profile type
                          <span className="text-right float-right">
                            {expanded.includes("collapseTwo22") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseTwo22") && <div id="collapseTwo22" className="collapse show" role="tabpanel" aria-labelledby="headingTwo22">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          <div className="custom-control custom-checkbox col-md-12">
                            <input type="checkbox" className="custom-control-input" defaultChecked={searchfilter?.profileType?.horoscope == "all"} onChange={(e) => { e.target.checked ? handleUpdate("profileType", { ...searchfilter.profileType, 'horoscope': "all" }) : handleUpdate("profileType", { ...searchfilter.profileType, 'horoscope': null }) }} id="withHoroscope" />
                            <label className="custom-control-label" htmlFor="withHoroscope">With Horoscope</label>
                          </div>
                          <div className="custom-control custom-checkbox col-md-12">
                            <input type="checkbox" className="custom-control-input" defaultChecked={searchfilter?.profileType?.photo == "all"} onChange={(e) => e.target.checked ? handleUpdate("profileType", { ...searchfilter.profileType, 'photo': "all" }) : handleUpdate("profileType", { ...searchfilter.profileType, 'photo': null })} id="withPhoto" />
                            <label className="custom-control-label" htmlFor="withPhoto">With Photo</label>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingThree33">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseThree33") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#accordion2" onClick={() => handleExpand("collapseThree33")} aria-expanded="false" aria-controls="collapseThree33">
                          Age
                          <span className="text-right float-right">
                            {expanded.includes("collapseThree33") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseThree33") && <div id="collapseThree33" className="collapse show" role="tabpanel" aria-labelledby="headingThree33">
                      <div className="card-body form-horizontal form-material">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group cus-select-drop reg-select-drop m-b-0">
                              <input data-style="form-control" type='number' value={searchfilter?.age?.from} onChange={(e) => handleUpdate("age", { ...searchfilter.age, "from": parseInt(e.target.value) })} className="mb-4 selectpicker form-control" min={19} max={60} />
                            </div>
                          </div>
                          <div className="col-md-2 p-10">
                            <span>TO</span>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group cus-select-drop reg-select-drop m-b-0">
                              <input data-style="form-control" type='number' value={searchfilter?.age?.to} onChange={(e) => handleUpdate("age", { ...searchfilter.age, "to": parseInt(e.target.value) })} className="mb-4 selectpicker form-control" min={19} max={60} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingOne111">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseOne111") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#collapseOne111" onClick={() => handleExpand("collapseOne111")} aria-expanded="false" aria-controls="collapseOne111">
                          Height
                          <span className="text-right float-right">
                            {expanded.includes("collapseOne111") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseOne111") && <div id="collapseOne111" className="collapse show" role="tabpanel" aria-labelledby="headingOne111">
                      <div className="card-body form-horizontal form-material">
                        <div className="form-group m-t-10 text-left">
                          <div className="custom-control custom-radio">
                            <input type="radio" id="inchs" name="heght" className="custom-control-input" checked />
                            <label className="custom-control-label" htmlFor="inchs">In Feet</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group cus-select-drop reg-select-drop m-b-0">
                              <input data-style="form-control" value={searchfilter?.height?.from} onChange={(e) => handleUpdate("height", { ...searchfilter.height, "from": e.target.value })} className="mb-4 selectpicker form-control" type="number" />
                            </div>
                          </div>
                          <div className="col-md-2 p-10">
                            <span>TO</span>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group cus-select-drop reg-select-drop m-b-0">
                              <input data-style="form-control" value={searchfilter?.height?.to} onChange={(e) => handleUpdate("height", { ...searchfilter.height, "to": e.target.value })} className="mb-4 selectpicker form-control" type="number" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingTwo222">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseTwo222") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#accordion2" onClick={() => handleExpand("collapseTwo222")} aria-expanded="false" aria-controls="collapseTwo222">
                          Marital status
                          <span className="text-right float-right">
                            {expanded.includes("collapseTwo222") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseTwo222") && <div id="collapseTwo222" className="collapse show" role="tabpanel" aria-labelledby="headingTwo222">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.maritalStatus?.map((item, i) => {
                              const checkboxId = `nvrmarrid_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox  col-md-12">
                                  <input
                                    type="checkbox"
                                    name='maratialStatus'
                                    checked={searchfilter?.maratialStatus?.includes(item.name ? item.name : "null")}
                                    value={item.name !== '' ? item.name : "null"}
                                    onChange={(e) => {
                                      e.target.checked
                                        ? handleUpdate(e.target.name, [
                                          ...searchfilter?.maratialStatus,
                                          e.target.value,
                                        ])
                                        : handleUpdate(
                                          e.target.name,
                                          searchfilter.maratialStatus.filter((x) => x !== e.target.value)
                                        );
                                    }}
                                    className="custom-control-input"
                                    id={checkboxId}
                                  />
                                  <label className="custom-control-label" htmlFor={checkboxId}>
                                    {item.name || "Not Disclosed"} ({item.count})
                                  </label>
                                </div>
                              );
                            })
                          }

                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="headingThree333">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("collapseThree333") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#accordion2" onClick={() => handleExpand("collapseThree333")} aria-expanded="false" aria-controls="collapseThree333">
                          Mother tongue
                          <span className="text-right float-right">
                            {expanded.includes("collapseThree333") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("collapseThree333") && <div id="collapseThree333" className="collapse show" role="tabpanel" aria-labelledby="headingThree333">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.motherTongue?.map((item, i) => {
                              const checkboxId = `motungguj_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='motherTongue' checked={searchfilter?.motherTongue?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.motherTongue, e.target.value]) : handleUpdate(e.target.name, searchfilter.motherTongue.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item?.new || "Not Disclosed"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="casthd">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("casthd1") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#casthd1" onClick={() => handleExpand("casthd1")} aria-expanded="false" aria-controls="casthd1">
                          Caste
                          <span className="text-right float-right">
                            {expanded.includes("casthd1") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("casthd1") && <div id="casthd1" className="collapse show" role="tabpanel" aria-labelledby="casthd">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.caste?.map((item, i) => {
                              const checkboxId = `kastriya_${i}`;
                              return (
                                <div className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" className="custom-control-input" name='caste' checked={searchfilter?.caste?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.caste, e.target.value]) : handleUpdate(e.target.name, searchfilter.caste.filter((x) => x != e.target.value))} id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item?.new || "Not Disclosed"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="starh">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("staraco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#staraco" onClick={() => handleExpand("staraco")} aria-expanded="false" aria-controls="staraco">
                          Star
                          <span className="text-right float-right">
                            {expanded.includes("staraco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("staraco") && <div id="staraco" className="collapse show" role="tabpanel" aria-labelledby="starh">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.star?.map((item, i) => {
                              const checkboxId = `strrohni_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='star' checked={searchfilter?.star?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.star, e.target.value]) : handleUpdate(e.target.name, searchfilter.star.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Disclosed"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="mnglkaco">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("manglikaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#manglikaco" onClick={() => handleExpand("manglikaco")} aria-expanded="false" aria-controls="manglikaco">
                          Manglik
                          <span className="text-right float-right">
                            {expanded.includes("manglikaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("manglikaco") && <div id="manglikaco" className={`collapse ${expanded.includes("manglikaco") && "show"}`} role="tabpanel" aria-labelledby="mnglkaco">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.manglik?.map((item, i) => {
                              let checkboxId = `manglik_${i}`
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='manglik' checked={searchfilter?.manglik?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.manglik, e.target.value]) : handleUpdate(e.target.name, searchfilter.manglik.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Disclosed"}</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="edcuctn">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("edcuctnaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#edcuctnaco" onClick={() => handleExpand("edcuctnaco")} aria-expanded="false" aria-controls="edcuctnaco">
                          Education
                          <span className="text-right float-right">
                            {expanded.includes("edcuctnaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("edcuctnaco") && <div id="edcuctnaco" className="collapse show" role="tabpanel" aria-labelledby="edcuctn">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.education?.map((item, i) => {
                              const checkboxId = `edubmedi_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='education' checked={searchfilter?.education?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.education, e.target.value]) : handleUpdate(e.target.name, searchfilter.education.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="inemp">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("inempaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#inempaco" onClick={() => handleExpand("inempaco")} aria-expanded="false" aria-controls="inempaco">
                          Employed in
                          <span className="text-right float-right">
                            {expanded.includes("inempaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("inempaco") && <div id="inempaco" className={`collapse ${expanded.includes("inempaco") && "show"}`} role="tabpanel" aria-labelledby="inemp">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.employedIn?.map((item, i) => {
                              const checkboxId = `empingov_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='employedIn' checked={searchfilter?.employedIn?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.employedIn, e.target.value]) : handleUpdate(e.target.name, searchfilter.employedIn.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="occptn">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("occptnaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#occptnaco" onClick={() => handleExpand("occptnaco")} aria-expanded="false" aria-controls="occptnaco">
                          Occupation
                          <span className="text-right float-right">
                            {expanded.includes("occptnaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("occptnaco") && <div id="occptnaco" className="collapse show" role="tabpanel" aria-labelledby="occptn">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.occupation?.map((item, i) => {
                              const checkboxId = `ocuptndoc_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='occupation' checked={searchfilter?.occupation?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.occupation, e.target.value]) : handleUpdate(e.target.name, searchfilter.occupation.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="anulinc">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("anulincaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#anulincaco" onClick={() => handleExpand("anulincaco")} aria-expanded="false" aria-controls="anulincaco">
                          Annual income
                          <span className="text-right float-right">
                            {expanded.includes("anulincaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("anulincaco") && <div id="anulincaco" className="collapse show" role="tabpanel" aria-labelledby="anulinc">
                      <div className="card-body">
                        <div className="form-group text-left ">
                          {
                            filter?.filter?.AnualIncome?.map((item, i) => {
                              const checkboxId = `income_${i}`;
                              return (
                                <div key={i} className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='AnualIncome' checked={searchfilter?.AnualIncome?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.AnualIncome, e.target.value]) : handleUpdate(e.target.name, searchfilter.AnualIncome.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="contrylv">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("contrylvaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#contrylvaco" onClick={() => handleExpand("contrylvaco")} aria-expanded="false" aria-controls="contrylvaco">
                          Country living in
                          <span className="text-right float-right">
                            {expanded.includes("contrylvaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("contrylvaco") && <div id="contrylvaco" className="collapse show" role="tabpanel" aria-labelledby="contrylv">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.country?.map((item, i) => {
                              const checkboxId = `conlivinind_${i}`;
                              return (
                                <div className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" name='country' checked={searchfilter?.country?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.country, e.target.value]) : handleUpdate(e.target.name, searchfilter.country.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.new || "Not Added"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className="card">
                    <div className="card-header" role="tab" id="citien">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("citienaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#citienaco" onClick={() => handleExpand("citienaco")} aria-expanded="false" aria-controls="citienaco">
                          Citizenship
                          <span className="text-right float-right">
                            {expanded.includes("citienaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("citienaco") && <div id="citienaco" className={`collapse ${expanded.includes("citienaco") && "show"}`} role="tabpanel" aria-labelledby="citien">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.citizenship.map((item, i) => {
                            const checkboxId = `citzsip_${i}`;
                            return (
                              <div className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" name='citizenship' checked={searchfilter?.citizenship?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.citizenship, e.target.value]) : handleUpdate(e.target.name, searchfilter.citizenship.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="phystss">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("phystssaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#phystssaco" onClick={() => handleExpand("phystssaco")} aria-expanded="false" aria-controls="phystssaco">
                          Physical status
                          <span className="text-right float-right">
                            {expanded.includes("phystssaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("phystssaco") && <div id="phystssaco" className={`collapse ${expanded.includes("phystssaco") && "show"}`} role="tabpanel" aria-labelledby="phystss">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.physicalStatus.map((item, i) => {
                            const checkboxId = `phystats_${i}`;
                            return (
                              <div className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" name='physicalStatus' checked={searchfilter?.physicalStatus?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.physicalStatus, e.target.value]) : handleUpdate(e.target.name, searchfilter.physicalStatus.filter((x) => x != e.target.value))} className="custom-control-input" id={checkboxId} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}

                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="cmplxion">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("cmplxionaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#cmplxionaco" onClick={() => handleExpand("cmplxionaco")} aria-expanded="false" aria-controls="cmplxionaco">
                          Complexion
                          <span className="text-right float-right">
                            {expanded.includes("cmplxionaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("cmplxionaco") && <div id="cmplxionaco" className={`collapse ${expanded.includes("cmplxionaco") && "show"}`} role="tabpanel" aria-labelledby="cmplxion">
                      <div className="card-body">
                        <div className="form-group text-left ">
                          {filter?.filter?.complexion.map((item, i) => {
                            const checkboxId = `comfar_${i}`;
                            return (
                              <div key={i} className="custom-control custom-radio col-md-12 m-b-10">
                                <input type="radio" id={checkboxId} name='complexion' checked={searchfilter?.complexion?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.complexion, e.target.value]) : handleUpdate(e.target.name, searchfilter.complexion.filter((x) => x != e.target.value))} className="custom-control-input" />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="bdtyp">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("bdtypaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#bdtypaco" onClick={() => handleExpand("bdtypaco")} aria-expanded="false" aria-controls="bdtypaco">
                          Body type
                          <span className="text-right float-right">
                            {expanded.includes("bdtypaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("bdtypaco") && <div id="bdtypaco" className={`collapse ${expanded.includes("bdtypaco") && "show"}`} role="tabpanel" aria-labelledby="bdtyp">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {
                            filter?.filter?.bodyType?.map((item, i) => {
                              const checkboxId = `bodyType_${i}`;
                              return (
                                <div className="custom-control custom-checkbox col-md-12">
                                  <input type="checkbox" className="custom-control-input" id={checkboxId} name='bodyType' checked={searchfilter?.bodyType?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.bodyType, e.target.value]) : handleUpdate(e.target.name, searchfilter.bodyType.filter((x) => x != e.target.value))} />
                                  <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Disclosed"} ({item.count})</label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="fethbts">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("fethbtsaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#fethbtsaco" onClick={() => handleExpand("fethbtsaco")} aria-expanded="false" aria-controls="fethbtsaco">
                          Eating habits
                          <span className="text-right float-right">
                            {expanded.includes("fethbtsaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("fethbtsaco") && <div id="fethbtsaco" className={`collapse ${expanded.includes("fethbtsaco") && "show"}`} role="tabpanel" aria-labelledby="fethbts">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.eatingHabit.map((item, i) => {
                            const checkboxId = `eatHabit_${i}`;
                            return (
                              <div key={i} className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" className="custom-control-input" id={checkboxId} name='eatingHabit' checked={searchfilter?.eatingHabit?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.eatingHabit, e.target.value]) : handleUpdate(e.target.name, searchfilter.eatingHabit.filter((x) => x != e.target.value))} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="fmlvalu">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("fmlvaluaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#fmlvaluaco" onClick={() => handleExpand("fmlvaluaco")} aria-expanded="false" aria-controls="fmlvaluaco">
                          Family Value
                          <span className="text-right float-right">
                            {expanded.includes("fmlvaluaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("fmlvaluaco") && <div id="fmlvaluaco" className={`collapse ${expanded.includes("fmlvaluaco") && "show"}`} role="tabpanel" aria-labelledby="fmlvalu">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.familyValue.map((item, i) => {
                            const checkboxId = `familyValue_${i}`;
                            return (
                              <div className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" className="custom-control-input" id={checkboxId} name='familyValue' checked={searchfilter?.familyValue?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.familyValue, e.target.value]) : handleUpdate(e.target.name, searchfilter.familyValue.filter((x) => x != e.target.value))} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="fmltyp">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("fmltypaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#fmltypaco" onClick={() => handleExpand("fmltypaco")} aria-expanded="false" aria-controls="fmltypaco">
                          Family Type
                          <span className="text-right float-right">
                            {expanded.includes("fmltypaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("fmltypaco") && <div id="fmltypaco" className={`collapse ${expanded.includes("fmltypaco") && "show"}`} role="tabpanel" aria-labelledby="fmltyp">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.familyType.map((item, i) => {
                            const checkboxId = `familyType_${i}`;
                            return (
                              <div key={i} className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" className="custom-control-input" id={checkboxId} name='familyType' checked={searchfilter?.familyType?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.familyType, e.target.value]) : handleUpdate(e.target.name, searchfilter.familyType.filter((x) => x != e.target.value))} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="fmlsts">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("fmlstsaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#fmlstsaco" onClick={() => handleExpand("fmlstsaco")} aria-expanded="false" aria-controls="fmlstsaco">
                          Family Status
                          <span className="text-right float-right">
                            {expanded.includes("fmlstsaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("fmlstsaco") && <div id="fmlstsaco" className={`collapse ${expanded.includes("fmlstsaco") && "show"}`} role="tabpanel" aria-labelledby="fmlsts">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.familyStatus.map((item, i) => {
                            const checkboxId = `familyStatus_${i}`;
                            return (
                              <div key={i} className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" className="custom-control-input" id={checkboxId} name='familyStatus' checked={searchfilter?.familyStatus?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.familyStatus, e.target.value]) : handleUpdate(e.target.name, searchfilter.familyStatus.filter((x) => x != e.target.value))} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="hvchild">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("hvchildaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#hvchildaco" onClick={() => handleExpand("hvchildaco")} aria-expanded="false" aria-controls="hvchildaco">
                          Have Children
                          <span className="text-right float-right">
                            {expanded.includes("hvchildaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("hvchildaco") && <div id="hvchildaco" className={`collapse ${expanded.includes("hvchildaco") && "show"}`} role="tabpanel" aria-labelledby="hvchild">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          <div className="custom-control custom-radio col-md-12">
                            <input
                              type="radio"
                              name="haveChildren"
                              value={true}
                              checked={searchfilter?.haveChildren === true}
                              onChange={() => handleUpdate("haveChildren", true)}
                              className="custom-control-input"
                              id="hvchldnntspcfy-yes"
                            />
                            <label className="custom-control-label" htmlFor="hvchldnntspcfy-yes">
                              Yes
                            </label>
                          </div>
                          <div className="custom-control custom-radio col-md-12">
                            <input
                              type="radio"
                              name="haveChildren"
                              value={false}
                              checked={searchfilter?.haveChildren === false}
                              onChange={() => handleUpdate("haveChildren", false)}
                              className="custom-control-input"
                              id="hvchldnntspcfy-no"
                            />
                            <label className="custom-control-label" htmlFor="hvchldnntspcfy-no">
                              No
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>}
                  </div>

                  <div className="card">
                    <div className="card-header" role="tab" id="prcrby">
                      <h5 className="mb-0">
                        <a className={`${expanded.includes("prcrbyaco") ? "show" : "collapsed"} link`} data-toggle="collapse" data-parent="#prcrbyaco" onClick={() => handleExpand("prcrbyaco")} aria-expanded="false" aria-controls="prcrbyaco">
                          Profile created by
                          <span className="text-right float-right">
                            {expanded.includes("prcrbyaco") ? <i className="mdi mdi-minus-box"></i> : <i className="mdi mdi-plus-box"></i>}
                          </span>
                        </a>
                      </h5>
                    </div>
                    {expanded.includes("prcrbyaco") && <div id="prcrbyaco" className={`collapse ${expanded.includes("prcrbyaco") && "show"}`} role="tabpanel" aria-labelledby="prcrby">
                      <div className="card-body">
                        <div className="form-group m-b-0 text-left">
                          {filter?.filter?.profileFor.map((item, i) => {
                            const checkboxId = `prfcrt_${i}`;
                            return (
                              <div key={i} className="custom-control custom-checkbox col-md-12">
                                <input type="checkbox" className="custom-control-input" id={checkboxId} name='profileFor' checked={searchfilter?.profileFor?.includes(item.name)} value={item.name} onChange={(e) => e.target.checked ? handleUpdate(e.target.name, [...searchfilter.profileFor, e.target.value]) : handleUpdate(e.target.name, searchfilter.profileFor.filter((x) => x != e.target.value))} />
                                <label className="custom-control-label" htmlFor={checkboxId}>{item.name || "Not Added"} ({item.count})</label>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className='card'>
                    <div className="col-md-12 m-t-5 button-container" ref={containerRef}>
                      <button
                        onClick={() => GetFilteredMatches(searchfilter)}
                        className={`btn col-md-12 scroll-button ${isFixed ? 'fixed' : 'static'}`}
                        style={{ position: isFixed ? "fixed" : "relative", width: isFixed ? "22%" : "100%" }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xlg-9 col-md-7 form-horizontal form-material box-shadow05">
                <div className="card bordernone">
                  {
                    loading ? (
                      <Loader />
                    ) : (<div className="search-profiles">
                      {
                        data?.match?.map((profile, i) => {
                          return (
                            <div key={i} className="search-profile-box">
                              <div className="search-profile-heading p-b-20">
                                <div className="prthumb">
                                  <img src={`${imageURL}${profile?.profilePicture}`} />
                                </div>
                                <h3 className="m-t-15"><Link to={`/viewProfile/${profile._id}`}>{profile.name}</Link></h3>
                                <span>{profile?.uniqueId || "A111111"} | Profile Created for {profile?.profileFor}</span>
                              </div>
                              <div className="search-profile-info">
                                <span><strong>Age:</strong> {profile?.age || "Not Added"} Yrs</span>
                                <span><strong>Height:</strong>{profile?.height?.feet || "Not Added"} Ft </span>
                                <span><strong>Religion:</strong>  {profile?.religion || "Not Added"} </span>
                                <span><strong>Caste:</strong> {profile?.caste || "Not Added"}</span>
                                <span><strong>Sub Caste:</strong> {profile?.subCaste || "Not Added"}</span>
                                <span><strong>Star:</strong>  {profile?.star || "Not Added"}</span>
                                <span><strong>Education :</strong> {profile?.education || "Not Added"}</span>
                                <span><strong>Profession:</strong> {profile?.occupation || "Not Added"}</span>
                                {profile.city ? <span><strong>Location:</strong> {profile?.city}, {profile?.state}, {profile?.country}</span> : null}
                              </div>
                              <span style={{ "float": "inline-end" }}>
                                <button className='btn btn-success' onClick={() => matchAccount(profile._id)}>
                                  Send Match
                                </button>
                              </span>
                            </div>
                          )
                        })
                      }
                    </div>)}
                </div>
                <div className='card-footer'>
                  <Pagination
                    limit={searchfilter.limit}
                    page={searchfilter.page}
                    pages={Math.ceil(data.total / searchfilter.limit)}
                    setPage={(e) => {
                      handleUpdate("page", e);
                    }}
                    setLimit={(e) => {
                      handleUpdate("limit", e);
                    }}
                  />
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default FamilyFinding
