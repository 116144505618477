import { createAction } from '@reduxjs/toolkit';
import navigation_constant from '../constants/navigation_constant';

export const fetch_clear = (props) => createAction(navigation_constant.FETCH_CLEAR)(props);
export const fetch_success = (props) => {
  return (dispatch) => {
    dispatch(createAction(navigation_constant.FETCH_SUCCESS)(props));
    setTimeout(() => {
      dispatch(fetch_clear());
    }, 10)
  };
};
export const fetch_failure = (props) => {
  return (dispatch) => {
    dispatch(createAction(navigation_constant.FETCH_FAILURE)(props));
    setTimeout(() => {
      dispatch(fetch_clear());
    }, 10)
  };
};
export const fetch_request = (props) => createAction(navigation_constant.FETCH_REQUEST)(props);
// export const toggle_alert = (props) => {
//   return (dispatch) => {
//     dispatch(createAction(navigation_constant.TOGGLE_ALERT)(props));
//     setTimeout(() => {
//       dispatch(fetch_clear());
//     }, 500);
//   };
// }

export default {
  fetch_success, fetch_failure, fetch_clear, fetch_request
};