import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Pagination from "./Pagination";
import Loader from "../loader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal } from "reactstrap";
import { addRecommendMember, recommendFamilyMembers } from "../../redux/services/userService";
import { ModalHeader, ModalBody, Label, Input, ModalFooter } from "reactstrap";
import navActions from "../../redux/actions/navigation_action";
import { CONFIG } from "../../constants/general";
import loadingActions from "../../redux/actions/loading_action";

const Recommendation = props => {
  const { token, loading, startLoading, endLoading, currentUser} = props;

  const [profiles, setProfiles] = useState([]);


  const getMemberRecommandations = async () => {
    let members = await recommendFamilyMembers(token, currentUser._id);
    setProfiles(members.relations);
    if (loading) endLoading();
  }

  useEffect(() => {
    getMemberRecommandations()
  }, [])

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <section className="breadcrumb-bg" style={{ padding: "0px", marginTop: "215px" }}>
        {" "}
      </section>
      <div className="theme-container container m-t-50 m-b-50">
        <div className="row">

          <div className="col-lg-8 col-xlg-8 col-md-8 form-horizontal form-material">
            <div className="card bordernone">
              <div className="card-body box-shadow05 m-t-20">
                <div className="col-md-12 col-xs-12 m-b-30 p-r-0 p-l-0 border-bottom1gray p-b-10 float-left">
                  <h3 className="float-left">
                    Recommended Family Members
                  </h3>
                </div>
                <div className="clearfix"></div>
                <div id="customer-needs" className="nav-1 cus-prmview">
                  <div className="item">
                    {profiles && profiles.length > 0 ? profiles.map((x, i) => (
                      <div key={i} className="random-prod cus-rendom-prod d-flex flex-row flex-wrap align-content-center align-items-center justify-content-between">
                        <div>
                          <div className="random-img">
                            <img alt="" src={`${CONFIG.REACT_APP_UPLOADS}/${x.userDetails[0].profilePicture}`} height="55" width="55" className="img-circle float-left" style={{ border: "1px solid #dedede", objectFit: 'cover', padding: "2px" }} />
                          </div>
                          <div className="random-text">
                            <h5 className="title-1 no-margin">
                              {" "}
                              <Link to={`/viewProfile/${x.user_id}`}>
                                <strong>{x.to_name}</strong>{" "}
                              </Link>{" "}
                            </h5>
                          </div>
                        </div>
                      </div>
                    )) : <>No Member Found For Recommendation</>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}



const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.user.currentUser,
  relations:state.data.relations,
  loading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recommendation);

