import user_constant from "../constants/user_constant";

export const setUserData = (user) => (dispatch) => {
  dispatch({
    type: user_constant.SET_USERDATA,
    currentUser: user,
  });
};

// export const setViewUser=()=>(dispatch)=>{

// }
