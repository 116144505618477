import loadingConstant from "../constants/loading_constant";

export const loadingStart = () => ({
  type: loadingConstant.LOADING_START
});

export const loadingEnd = (key) => ({
  type: loadingConstant.LOADING_END
});

export default { loadingStart, loadingEnd }