import userconstant from "../constants/user_constant";

const userState = {
  currentUser: {},
};

function user(state = userState, action) {
  switch (action.type) {
    case userconstant.SET_USERDATA:
      return {
        ...state,
        currentUser: action.currentUser,
      };
    case userconstant.GET_USERDATA:
      return {
        ...state,
        currentUser: action.user,
      };
  
    default:
      return state;
  }
}

export default user;
