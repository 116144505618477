import React, { useEffect, useState } from 'react'
import { getHoroscope, getUser } from '../../redux/services/userService';
import navActions from "../../redux/actions/navigation_action";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Row, Col } from "react-bootstrap";
import { CONFIG } from '../../constants/general';
import loadingActions from "../../redux/actions/loading_action";

const ViewHoroscopes = (props) => {
  const { userId, currentUser, token, loading, startLoading, endLoading, success, failure, getMemberRequestsData } = props;
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [userReload, setUserReload] = useState(false);
  const [error, seterror] = useState("");
  const [horoscope, setHoroscope] = useState(null);

  const loadUser = (id) => {
    getUser(token, id).then((data) => {
      if (data.code === 5) {
        data.user.youBlocked = data.youBlocked;
        data.user.requested = data.requested;
        data.user.blocked = data.blocked;
        data.user.matchRequest = data.matchRequest;
        data.user.rateAllowed = data.rateAllowed;
        data.user.isMatchable = data.user.gender != currentUser.gender && data.isMatchable;
        data.user.rated = data.rated;
        data.user.rating = data.rating;
        data.user.sendMessageMe = data.sendMessageMe;
        data.user.sendRelationRequest = data.sendRelationRequest;
        data.user.commentAllowed = data.commentAllowed;
        data.user.comments = data.comments;
        data.user.isShortlisted = data.isShortlisted;
        data.user.matchPercentage = data.matchPercentage
        // data.user.uploads = data.user.uploads.map(x => {src : imageURL+"/"+x.src} );
        setUser(data.user);
        if (data.user.setting.horoscope.privacy != "hpassword") {
          getHoroscopeDetails(id).then(() => {
            if(loading) endLoading();
          })
        } else {
          if(loading) endLoading();
        }
      }
    });
    setUserReload(false)
  };

  const getHoroscopeDetails = async (id) => {
    let result = await getHoroscope(token, id, { password: password })
    if (result.code == 5) {
      setHoroscope(result.data?.horoscope || {})
    } else {
      seterror(result.error)
      failure(result.error)
    }
  }
  useEffect(() => {
    if (props.match.params.id) {
      loadUser(props.match.params.id)
    }
  }, [props.match.params.id]);

  return (
    <>
      {!loading && <div>
        <section className="breadcrumb-bg ">
          <div className="theme-container container ">
            <div className="site-breadcumb white-clr">
              <div className="text-white ">
                <div className="row">
                  <div className="profile-header-img ribbon-wrapper">
                    <img
                      src={`${CONFIG.REACT_APP_UPLOADS}/${user.profilePicture}`}
                      className="img-circle"
                      width="100"
                      height="100"
                      alt="profileImg"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div className="profile-header-info align-content-center">
                    <h4 className="card-title text-white m-b-20">
                      <span>{user.basicDetails.name} </span>
                      {/* {user._id !== currentUser?._id && ( */}
                      <>
                        {user.tag && <div className="bg-warning text-white cus-rebon-new">{user.tag}</div>}
                        {user.isMatchable && <div style={{ display: "inline-block", paddingLeft: "10px", marginTop: "10px" }}>
                          <span style={{ fontSize: "10px" }}>
                            {" "}
                            MATCH SCORE <strong>({user.matchPercentage}%)</strong>
                          </span>
                          <div className="progress" style={{ height: "6px" }}>
                            <div
                              className="progress-bar active progress-bar-striped bg-danger"
                              style={{ width: `${user.matchPercentage}%`, height: "6px" }}
                              role="progressbar"
                            >
                              <span className="sr-only">{user.matchPercentage}% Complete</span>
                            </div>
                          </div>
                        </div>}
                      </>
                      {/* // )} */}
                    </h4>
                    {/* <h6 className="card-subtitle text-white m-b-15">@jamesandre</h6> */}
                    <h6 className="card-subtitle text-white m-b-10">Profile Created for {user.basicDetails.profileFor}</h6>
                    <p className="text-white">{user.aboutMe && user.aboutMe}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="theme-container container m-t-50 m-b-50">
          {!error ? <>
            {
              (horoscope && user.setting.horoscope.privacy == "hpassword") ? <>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Password :
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="password" name="password" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} maxlength="30" ></Form.Control>
                  </Col>
                </Form.Group>
                <Button type="submit" className="btn btn-success" onClick={() => getHoroscopeDetails(props.match.params.id)}>
                  Update
                </Button>
              </> : <>
                <div className="col-md-12 col-xs-12 m-b-10 p-0">
                  <div className="row">
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>DOB : </strong>
                      {horoscope?.DOB ? moment(horoscope.DOB).format("DD MMM YYYY") : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Country: </strong>
                      {horoscope?.country?.name ? horoscope.country.name : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>State: </strong>
                      {horoscope?.state?.name ? horoscope.state.name : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>City: </strong>
                      {horoscope?.city?.name ? horoscope.city.name : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>District : </strong>
                      {horoscope?.district ? horoscope.district : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong> Taluka: </strong>
                      {horoscope?.taluka ? horoscope.taluka : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Village: </strong>
                      {horoscope?.village ? horoscope.village : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Latitude: </strong>
                      {horoscope?.latitude ? horoscope.latitude : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Longitude : </strong>
                      {horoscope?.longitude ? horoscope.longitude : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong> Nakshtra: </strong>
                      {horoscope?.nakshtra ? horoscope.nakshtra : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Charan: </strong>
                      {horoscope?.charan ? horoscope.charan : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Moon: </strong>
                      {horoscope?.moon ? horoscope.moon : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Gan : </strong>
                      {horoscope?.gan ? horoscope.gan : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong> Yoni: </strong>
                      {horoscope?.yoni ? horoscope.yoni : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Nadi: </strong>
                      {horoscope?.nadi ? horoscope.nadi : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Varn : </strong>
                      {horoscope?.varn ? horoscope.varn : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong> Vashya: </strong>
                      {horoscope?.vashya ? horoscope.vashya : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Varga: </strong>
                      {horoscope?.varga ? horoscope.varga : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong>Muhurta : </strong>
                      {horoscope?.muhurta ? horoscope.muhurta : "Not Added"}
                    </div>
                    <div className="col-md-6 col-xs-12 m-b-10">
                      <strong> Hansak: </strong>
                      {horoscope?.hansak ? horoscope.hansak : "Not Added"}
                    </div>
                  </div>
                </div>
              </>
            }
          </> : <>{error}</>}
        </div>
      </div>}
    </>
  )
}

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  currentUser: state.user.currentUser,
  token: state.auth.token,
  getMemberRequestsData: state.getMemberRequestsData,
  relations: state.data.relations,
  loading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  request: (props) => dispatch(navActions.fetch_request(props)),
  clear: (props) => dispatch(navActions.fetch_clear(props)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewHoroscopes));
