import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { logoutAction } from "../redux/actions/auth_action";
import { Modal, Button } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import useScrollTop from "./helper/useScrollTop";
import { Link } from "react-router-dom";
import { getNotifications, getUser } from "../redux/services/userService";
import { setUserData } from "../redux/actions/user_action";
import { CONFIG } from "../constants/general";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Header = (props) => {
  const { token, userId, logout, history, userRole, currentUser } = props;
  // const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();
  const isTopOfPage = useScrollTop();
  const imageURL = CONFIG.REACT_APP_UPLOADS + "/";
  const [isOpen, toggleOpen] = useState(false);
  const [user, setUser] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [feed, setFeed] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownNotificationsRef = useRef(null);
  const dropdownFeedRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isFeedDropdownOpen, setIsFeedDropdownOpen] = useState(false);


  const getNotification = async () => {
    const response = await getNotifications(token, { page: 1, limit: 5, type: "all" });
    setNotifications(response.notifications)
    const getFeed = await getNotifications(token, { page: 1, limit: 5, type: "feed" });
    setFeed(getFeed.notifications)
  }
  useEffect(() => {
    getNotification()
  }, [])


  const handleDropdownToggle = () => {
    setIsExpanded(!isExpanded);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getUserData = async () => {
    try {
      const response = await getUser(token, userId);
      if (response.code === 5) {
        props.setUserData(response.user)
        setUser(response.user);
      };
    } catch (error) {
      console.warn("Error: Error while getting user.", error);
    }
  }

  useEffect(() => {
    if (!currentUser.user) getUserData();
  }, [currentUser.user]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
      if (dropdownNotificationsRef.current && !dropdownNotificationsRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (dropdownFeedRef.current && !dropdownFeedRef.current.contains(event.target)) {
        setIsFeedDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="main-wrapper">
      <div className="header-section">
        <div className="header-top side-padding">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="header-links">
                  <a rel="noopener">
                    <i className="fa fa-phone"></i> +91-9824264610
                  </a>
                  <a href="mailto:info@familytree.com">
                    <i className="fa fa-envelope"></i> info(at)familytree.com
                  </a>

                </div>
              </div>
              <div className="col">
                <div className="header-links">
                  <a href="/FAQs.html">
                    <i className="fa fa-support"></i> Help/Support
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={!isTopOfPage ? "header-bottom header-sticky side-padding is-sticky" : "header-bottom header-sticky side-padding"}>
          <div className="container">
            <div className="row">
              <div className="col">
                {location.pathname === '/dashboard' ? (
                  <div className="logo" style={{ cursor: 'not-allowed' }}>
                    <img src={logo} alt="Logo" style={{ objectFit: 'cover' }} />
                  </div>
                ) : (
                  <Link to="/dashboard" className="logo">
                    <img src={logo} alt="Logo" style={{ objectFit: 'cover' }} />
                  </Link>
                )}
              </div>
              <div className="col d-none d-lg-block">
                <div className="main-menu">
                  <nav>
                    <ul>
                      <li>
                        {location.pathname === '/dashboard' ? (
                          <span style={{ cursor: 'not-allowed', color: 'gray' }}>Dashboard</span>
                        ) : (
                          <Link to="/dashboard">Dashboard</Link>
                        )}
                      </li>
                      <li className="">
                        <a href="/about-us.html">about us</a>
                      </li>
                      <li>
                        <a href="/contact.html">contact us</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="head-content top-navbar" style={{ width: 'auto' }}>
                <div className="topbar" style={{ float: 'none', display: 'inline-block' }}>
                  <div className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-nav">
                      <div className="nav-item dropdown cus-select-drop" ref={dropdownNotificationsRef}>
                        <a
                          className="nav-link dropdown-toggle waves-effect waves-dark"
                          onClick={toggleDropdown}
                          style={{ display: 'inline-block', color: '#303030' }}
                        >
                          <i className="fa fa-bell"></i>
                          <div className="notify">
                            {/* <span className="heartbit"></span> */}
                            <span className="point"></span>
                          </div>
                        </a>
                        {isDropdownOpen && (
                          <div className="dropdown-menu dropdown-menu-right mailbox animated flipInY form-control box-shadow05" onClick={() => setIsDropdownOpen(false)}>
                            <ul>
                              <li>
                                <div className="drop-title">Notifications</div>
                              </li>
                              <li>
                                <div className="message-center">
                                  {
                                    notifications.length > 0 ? notifications.map((item, i) => {
                                      return (
                                        <Link key={i} to={`/viewProfile/${item.sender?._id}`}>
                                          <div className="user-img"> <img src={imageURL + item?.sender?.profilePicture} alt="user" className="img-circle" /> <span className="profile-status busy pull-right"></span> </div>
                                          <div className="mail-contnet">
                                            {item.content}
                                          </div>
                                        </Link>
                                      )
                                    }) : <a href="javascript:void(0)">
                                      <div className="mail-contnet">
                                        No Notifications Yet
                                      </div>
                                    </a>
                                  }
                                </div>
                              </li>
                              <li>
                                <Link className="nav-link text-center link" to="/notifications">
                                  <strong>Check all notifications</strong>
                                  <i className="fa fa-angle-right"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="nav-item dropdown cus-select-drop" ref={dropdownFeedRef}>
                        <a className="nav-link dropdown-toggle waves-effect waves-dark"
                          style={{ display: 'inline-block', color: '#303030' }}
                          onClick={() => setIsFeedDropdownOpen(!isFeedDropdownOpen)}>
                          <i className="mdi mdi-view-grid" style={{ cursor: "pointer" }}></i>
                        </a>
                        {isFeedDropdownOpen && (
                          <div className="dropdown-menu dropdown-menu-right mailbox animated flipInY form-control box-shadow05" onClick={() => setIsDropdownOpen(false)}>
                            <ul>
                              <li>
                                <div className="drop-title">Feed</div>
                              </li>
                              <li>
                                <div className="message-center">
                                  {
                                    feed.length > 0 ? feed.map((item, i) => {
                                      return (
                                        <Link key={i} to={`/viewProfile/${item.sender?._id}`} onClick={() => { setIsFeedDropdownOpen(false) }}>
                                          <div className="user-img"> <img src={imageURL + item?.sender?.profilePicture} alt="user" className="img-circle" /> <span className="profile-status busy pull-right"></span> </div>
                                          <div className="mail-contnet">
                                            {item.content}
                                          </div>
                                        </Link>
                                      )
                                    }) : <a href="javascript:void(0)">
                                      <div className="mail-contnet">
                                        No Feed Updates Yet
                                      </div>
                                    </a>
                                  }
                                </div>
                              </li>
                              <li onClick={() => { setIsFeedDropdownOpen(false) }}>
                                <Link className="nav-link text-center link" to="/feed">
                                  <strong>Check all Feed</strong>
                                  <i className="fa fa-angle-right"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="user-head-icon " style={{ float: "none", display: "inline-block" }}>
                  <div className="nav-item dropdown cus-select-drop" ref={dropdownRef}>
                    <a className="nav-link dropdown-toggle profile-pic p-0" onClick={handleDropdownToggle} aria-haspopup="true" aria-expanded={isExpanded ? "true" : "false"}>
                      {currentUser?.profilePicture && <img
                        src={`${CONFIG.REACT_APP_UPLOADS}/${currentUser?.profilePicture}`}
                        alt="user"
                        height="55"
                        width="55"
                        className="img-circle"
                        style={{ objectFit: 'cover' }}
                      />}
                      <span className="hidden-md-down">
                        {/* {currentUser.basicDetails.name} */}
                      </span>
                    </a>
                    <div className={`dropdown-menu dropdown-menu-right animated ${isExpanded ? 'flipInY' : ''} box-shadow05`} style={{ display: isExpanded ? 'block' : 'none' }}>
                      <Link to="/setting" className="dropdown-item" onClick={handleDropdownToggle}>
                        <i className="ti-settings"></i> Account Setting
                      </Link>
                      <Link to={`/viewProfile/${userId}`} className="dropdown-item" onClick={handleDropdownToggle}>
                        <i className="ti-user"></i> My Profile
                      </Link>
                      <Link to="/mailList" className="dropdown-item" onClick={handleDropdownToggle}>
                        <i className="fa fa-envelope-o"></i> Inbox
                      </Link>
                      {/* <Link to="/profilePreview" className="dropdown-item" onClick={handleDropdownToggle}>
                        <i className="fa fa-user-o"></i> Profile Shortlist
                      </Link> */}
                      <Link to="/newMatches" className="dropdown-item" onClick={handleDropdownToggle}>
                        <i className="fa fa-user-o"></i> Profile Matches
                      </Link>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" onClick={() => { handleDropdownToggle(); toggleOpen(true) }}>
                        <i className="fa fa-power-off"></i> Logout
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mobile-menu d-block d-lg-none col"></div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={isOpen}
        backdrop="static"
        keyboard={false}
        onHide={() => toggleOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to logout ?
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="btn btn-success" onClick={() => logout(history, userRole)}>
            Logout
          </Button>
          <Button type="button" className="btn btn-info" onClick={() => toggleOpen(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  userId: state.auth.userId,
  userRole: state.auth.userRole,
  currentUser: state.user.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  logout: (history) => dispatch(logoutAction(history)),
  setUserData: (user) => dispatch(setUserData(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
