import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Pagination from "./Pagination";
import Loader from "../loader";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Button, Modal, Tooltip } from "reactstrap";
import { createMember, deleteFamilyMember, getMember, getTreeViewRequest, getUser, recommendFamilyMembers, updateMemberFromRelations, updateUserTreeView } from "../../redux/services/userService";
import { ModalHeader, ModalBody, Label, Input, ModalFooter } from "reactstrap";
import navActions from "../../redux/actions/navigation_action";
import { BROTHER_CHILDREN_LIST, CAN_BE_PARENTS, CAN_BE_SPOUSE, CHILDREN_LIST, CHILDREN_OBJECT, CONFIG, FATHER_SIDE_RELATIONS, FATHER_SIDE_RELATION_OBJECT, GRAND_CHILDREN_LIST, IN_LAW_RELATIONS, IN_LAW_SPOUSE_RELATIONS, IS_MARRIED_FEMALE, IS_MARRIED_MALE, IS_SINGLE, NOT_DUPLICATE_RELATIONS, PARENTS, PARENT_RELATIONS, RELATIONS, RELATION_ENABLE, REQUIRED_PARENT, SINGLE_USER_DISABLED_RELATIONS, SISTER_CHILDREN_LIST } from "../../constants/general";
import InfoIcon from '@mui/icons-material/Info';
import { setUserData } from "../../redux/actions/user_action";
import loadingActions from "../../redux/actions/loading_action";
import { getAds } from "../../utils/utils";

const FamilyMemberList = props => {
  const { token, currentUser, loading, ads, endLoading, relations, success, failure, setUserData } = props;
  const [tooltip, setTooltip] = useState(null);
  const [tab, setTab] = useState("father");
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMember, setDeleteMember] = useState();
  const [editMember, setEditMember] = useState(false);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [relationOption, setRelationOption] = useState([]);
  const [adsContent, setAdsContent] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [treeView, setTreeView] = useState([]);


  const getMemberRecommandations = async () => {
    let members = await recommendFamilyMembers(token, currentUser._id);
    setProfiles(members.relations);
  }

  const findAds = () => {
    let arr = ["right", "left", "middle"]
    let obj = {}
    let adsData = ads
    arr.forEach((x) => {
      let { htmlContent, remainingAds } = getAds([x], adsData)
      if (htmlContent) {
        obj[x] = htmlContent
        adsData = remainingAds
      } else {
        let { htmlContent, remainingAds } = getAds(["anywhere"], adsData)
        obj[x] = htmlContent
        adsData = remainingAds
      }
    })
    setAdsContent(obj)
  }

  const deleteUser = async () => {
    let result = await deleteFamilyMember(token, deleteMember)
    if (result.success) {
      success({ message: result.message })
    } else {
      failure({ message: result.message })
    }
    getFamilyMember()
  }
  const getFamilyMember = async () => {
    try {
      const data = await getMember(token);
      setFamilyMembers(data.data);
      currentUser["familyMember"] = data.data;
      setUserData(currentUser);
    } catch (error) {
      console.error("An error occurred while fetching family members:", error);
    }
  };


  const addnewMember = async () => {
    try {
      let memberObj = editMember
      let findCurrentUser = familyMembers.find(x => x.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112")
      if (!editMember.to_name || editMember.to_name == "") {
        failure({ message: "Name cann't be empty" })
        return
      }
      if (!editMember?.relationDetails?.relation_id) {
        failure({ message: "Select Relation" })
        return
      }
      let parentReq = editMember.user_id ? true : (editMember.relationDetails.memberFromId == findCurrentUser._id && REQUIRED_PARENT.includes(editMember?.relationDetails?.relation_id))
        ? false :
        (editMember.relationDetails.memberFromId == findCurrentUser._id && GRAND_CHILDREN_LIST.includes(editMember?.relationDetails?.relation_id)) ||
          (editMember.relationDetails.memberFromId == findCurrentUser._id && BROTHER_CHILDREN_LIST.includes(editMember?.relationDetails?.relation_id)) ||
          (editMember.relationDetails.memberFromId == findCurrentUser._id && SISTER_CHILDREN_LIST.includes(editMember?.relationDetails?.relation_id))
          ? false
          : true
      if ((!parentReq) && (!editMember?.parentDetails?._id)) {
        failure({ message: "Select parents to submit" })
        return
      }
      let spouseReq = editMember.user_id ? true : (editMember.relationDetails.memberFromId == findCurrentUser._id && FATHER_SIDE_RELATIONS.includes(editMember?.relationDetails?.relation_id)) || (editMember.relationDetails.memberFromId == findCurrentUser._id && IN_LAW_RELATIONS.includes(editMember?.relationDetails?.relation_id)) ? false : true
      if ((!spouseReq) && (!editMember?.spouseDetails?._id)) {
        failure({ message: "Select spouse to submit" })
        return
      }
      if (editMember.to_name == "" || editMember.to_name == null || editMember.relationDetails.relation_id == "") {
        failure({ message: "Enter Details Properly." })
        return
      }
      if ((editMember.relationDetails.memberFromId == findCurrentUser._id && CHILDREN_LIST.includes(editMember.relationDetails.relation_id))) {
        if (currentUser.gender == "male") {
          let myObj = familyMembers.find((x) => x.user_id == currentUser._id)
          memberObj['parentDetails'] = { _id: myObj._id }
        } else {
          let myObj = familyMembers.find((x) => x.user_id == currentUser._id)
          memberObj['parentDetails'] = { _id: myObj.spouse_id }
        }
      }
      if (!memberObj?.parentDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && (["636a7c0fdd89cb0e2cf22475", "664307f4c389452f99dbe589", "66430819c389452f99dbe58a"].includes(editMember.relationDetails.relation_id))) {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef50d3710aac0934698c7f" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (!myObj) {
          failure({ message: "Enter grandfather to add uncle" })
          return
        }
        memberObj['parentDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.parentDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef51ae710aac0934698c8d") {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef518c710aac0934698c86" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (myObj) memberObj['parentDetails'] = { _id: myObj._id }
      }
      if (editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef518c710aac0934698c86") {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef51ae710aac0934698c8d" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (!myObj) {
          failure({ message: "Enter husband to father in law" })
          return
        }
      }
      if (!memberObj?.parentDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && (editMember.relationDetails.relation_id == "636a7c0fdd89cb0e2cf22477" || editMember.relationDetails.relation_id == "636a7c0fdd89cb0e2cf22479")) {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef50d3710aac0934698c7f" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (!myObj) {
          failure({ message: "Enter grandfather to add father's sisters" })
          return
        }
        memberObj['parentDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.parentDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && (editMember.relationDetails.relation_id == "636a7c0fdd89cb0e2cf2247b" || editMember.relationDetails.relation_id == "636a7c0fdd89cb0e2cf2247d")) {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef518c710aac0934698c86" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (!myObj) {
          failure({ message: "Enter father in law" })
          return
        }
        memberObj['parentDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.spouseDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eebbd0f61ce44163c22a6d2") {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef5174710aac0934698c85" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        memberObj['spouseDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.spouseDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef51c2710aac0934698c8e") {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef518c710aac0934698c86" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        memberObj['spouseDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.spouseDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef54eb710aac0934698cb0") {
        let myObj = familyMembers.find((x) => x.user_id == currentUser._id)
        memberObj['spouseDetails'] = { _id: myObj._id }
      }
      if (!memberObj?.spouseDetails?._id && editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef510e710aac0934698c80") {
        let myObj = familyMembers.find((x) => x.relationDetails.relation_id == "5eef50d3710aac0934698c7f" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        memberObj['spouseDetails'] = { _id: myObj._id }
      }
      if (editMember.relationDetails.memberFromId == findCurrentUser._id && editMember.relationDetails.relation_id == "5eef50d3710aac0934698c7f") {
        let myObj = familyMembers.filter((x) => x.relationDetails.relation_id == "5eef50d3710aac0934698c7f" && editMember.relationDetails.memberFromId == findCurrentUser._id && String(editMember._id) != String(x._id))
        let pardada = familyMembers.filter((x) => x.relationDetails.relation_id == "5eef5285710aac0934698c94" && editMember.relationDetails.memberFromId == findCurrentUser._id)
        if (myObj.length > 0 && pardada.length == 0) {
          failure({ message: "Enter pardada first to add dada" })
          return
        }
      }
      let result = await createMember(token, memberObj)
      success({ message: "Family member updated successfully" })
      getFamilyMember()
      setModal(false)
    } catch (error) {
      console.log(error);
    }
  }

  const UpdateMemberRelation = async () => {
    try {
      let result = await updateMemberFromRelations(token, { id: currentUser._id })
      if (result.code == 5) {
        getFamilyMember()
        success({ message: 'Member updated successfully' })
      } else {
        failure({ message: "Something went wrong" })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const findEnabledRelations = () => {
    let relation = relations.filter((relation) => {
      let isEnable = true
      if (currentUser.gender === "male" && (currentUser.basicDetails.maritalStatus === "Married" || currentUser.basicDetails.maritalStatus === "Engaged")) {
        isEnable = relation._id !== IS_MARRIED_FEMALE[0];
      } else if (currentUser.gender === "female" && (currentUser.basicDetails.maritalStatus === "Married" || currentUser.basicDetails.maritalStatus === "Engaged")) {
        isEnable = relation._id !== IS_MARRIED_MALE[0];
      }
      if (currentUser.basicDetails.maritalStatus === "Single") {
        isEnable = !SINGLE_USER_DISABLED_RELATIONS.includes(relation._id);
      }
      if (String(relation._id) == "65dc7cb2d189e84ba2c23112") {
        isEnable = false;
      }
      if (Object.keys(RELATION_ENABLE).includes(relation._id)) {
        let requiredMember = RELATION_ENABLE[relation._id];
        if (familyMembers.filter((y) => requiredMember.includes(String(y.relationDetails.relation_id))).length == 0) {
          isEnable = false
        }
      }
      if (isEnable) {
        return true
      }
    });
    setRelationOption(() => {
      let options = [];
      relation.forEach((relation) => {
        options.push({ option: relation[currentUser.basicDetails.motherTongue.mothertongue], no: relation["_id"], gender: relation?.gender });
      });
      return options;
    });
  }

  const checkEditEnable = (member) => {
    if (member.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112") return false
    // if (member.relationDetails?.memberFrom && member.relationDetails?.memberFrom.toLowerCase() != currentUser.basicDetails.name.toLowerCase()) return false
    return true
  }

  const checkDeleteEnable = (member) => {
    if (member.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112") return false
    if (familyMembers.filter(x => x.spouse_id == member._id || x.parent_id == member._id).length > 0) return false
    // if (member.relationDetails?.memberFrom && member.relationDetails?.memberFrom.toLowerCase() != currentUser.basicDetails.name.toLowerCase()) return false
    return true
  }

  const getTreeRequests = async () => {
    try {
      const result = await getTreeViewRequest(token)
      setTreeView(result.data)
    } catch (error) {
      console.log(error)
    }
  }

  const changeStatus = async (id, status) => {
    try {
      let result = await updateUserTreeView(token, { id: id, status: status })
      if (result.code == 5) {
        success({ message: result.message })
        getTreeRequests();
      } else {
        failure({ message: result.error })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getMemberRecommandations();
        await getFamilyMember();
        await getTreeRequests();
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      } finally {
        if (loading) {
          endLoading();
        }
      }
    };
    findAds()
    fetchData();
  }, []);


  useEffect(() => {
    findEnabledRelations();
  }, [JSON.stringify(familyMembers)]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <section className="breadcrumb-bg" style={{ padding: "0px", marginTop: "215px" }}>
        {" "}
      </section>
      <div className="theme-container container m-t-50 m-b-50">
        <div className="form-horizontal form-material">
          <div className="row">
            <div className="card bordernone col-sm-8">
              {adsContent.middle && <div className="card-body bg-white m-b-20 box-shadow05">
                <div
                  className="ads-div"
                  dangerouslySetInnerHTML={{ __html: adsContent.middle }}
                />
              </div>}
              <div className="card-body box-shadow05 m-t-20">
                <div className="col-md-12 col-xs-12 m-b-30 p-r-0 p-l-0 border-bottom1gray p-b-10 float-left">
                  <h3 className="float-left">
                    Family Members
                  </h3>
                  <div className="float-right">
                    <span onClick={() => { setModal(true); setEditMember({ to_name: "", relationDetails: { memberFromId: familyMembers.find(x => x.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112")?._id } }) }} style={{ cursor: "pointer" }}>
                      <strong>Add</strong>
                    </span>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div id="customer-needs" className="nav-1 cus-prmview">
                  <ul className="nav nav-tabs" role="tablist" style={{ marginBottom: "15px" }}>
                    <li className="nav-item" onClick={() => { setTab("father") }}>
                      {" "}
                      <a className={`nav-link ${tab === "father" ? "active" : ""}`} data-toggle="tab" role="tab">
                        <span className="hidden-sm-up">
                          <i className="ti-home"></i>
                        </span>{" "}
                        <span className="hidden-xs-down">Father</span>
                      </a>{" "}
                    </li>
                    <li className="nav-item" onClick={() => { setTab("mother") }}>
                      {" "}
                      <a className={`nav-link ${tab === "mother" ? "active" : ""}`} data-toggle="tab" role="tab">
                        <span className="hidden-sm-up">
                          <i className="ti-user"></i>
                        </span>{" "}
                        <span className="hidden-xs-down">Mother</span>
                      </a>{" "}
                    </li>
                    <li className="nav-item" onClick={() => { setTab("other") }}>
                      {" "}
                      <a className={`nav-link ${tab === "other" ? "active" : ""}`} data-toggle="tab" role="tab">
                        <span className="hidden-sm-up">
                          <i className="ti-email"></i>
                        </span>{" "}
                        <span className="hidden-xs-down">Other</span>
                      </a>{" "}
                    </li>
                  </ul>
                  <div className="item">
                    <div className="col-md-12 col-xs-12 m-b-10 p-0">
                      <div className="row">
                        <div className="col-3 m-b-10">Name</div>
                        <div className="col-2 m-b-10">Relation</div>
                        <div className="col-2 m-b-10">Parent</div>
                        <div className="col-2 m-b-10">Spouse</div>
                        <div className="col-2 m-b-10"></div>
                      </div>
                      {(familyMembers && tab == "father") &&
                        familyMembers.filter(x => x.relationDetails.side == "father").map((x, i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="col-3 m-b-10">
                                {x.relationUserId ? x.relationUserId.basicDetails.name : x.to_name}
                              </div>
                              <div className="col-2 m-b-10">
                                {
                                  x.relationDetails?.memberFrom && x.relationDetails?.memberFrom.toLowerCase() != currentUser.basicDetails.name.toLowerCase() ?
                                    <span id={`tooltip-${i}`}>
                                      {x?.relationDetails?.memberFrom}
                                      <InfoIcon />
                                      {"(" + relations.find((rel) => rel._id == x.relationDetails?.relation_id)[currentUser.basicDetails.motherTongue.mothertongue] || "NA" + ")"}
                                      <Tooltip
                                        placement={"bottom"}
                                        isOpen={tooltip === i}
                                        target={`tooltip-${i}`}
                                        toggle={() => { if (tooltip == i) { setTooltip(null) } else { setTooltip(i) } }}
                                      >
                                        {x.memberFromRelations.map((item, key) => {
                                          let next = x.memberFromRelations.length - 1 > key
                                          return (
                                            <span key={key}>
                                              {relations.find((rel) => rel._id == item)[currentUser.basicDetails.motherTongue.mothertongue]}{next ? "=>" : ""}
                                            </span>
                                          )
                                        })}
                                      </Tooltip>
                                    </span>
                                    : <>
                                      <span id={`tooltip-${i}`}>
                                        {relations.find((rel) => rel._id == x.relationDetails?.relation_id) ? relations.find((rel) => rel._id == x.relationDetails?.relation_id)[currentUser.basicDetails.motherTongue.mothertongue] : "NA"}
                                      </span>
                                    </>
                                }
                              </div>
                              <div className="col-2 m-b-10">
                                {x.parentDetails ? x.parentDetails?.to_name : "-"}
                              </div>
                              <div className="col-2 m-b-10">
                                {x.spouseDetails ? x.spouseDetails?.to_name : "-"}
                              </div>
                              {/* <div className="col-2 m-b-10">
                                {x.relationDetails.side ? x.relationDetails.side : "-"}
                              </div> */}
                              <div className="col-2 m-b-10">
                                <div className="btn-group" style={{ gap: "3px" }}>
                                  {checkEditEnable(x) ? <Button className="btn btn-success" style={{ "padding": "5px 14px" }} onClick={() => { setModal(true); setEditMember(x) }}>Edit</Button> : <></>}
                                  {checkDeleteEnable(x) ? <Button className="btn btn-danger" style={{ "padding": "5px 14px" }} onClick={() => { setDeleteModal(true); setDeleteMember(x._id) }}>Delete</Button> : <></>}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {(familyMembers && tab == "mother") &&
                        familyMembers.filter(x => x.relationDetails.side == "mother").map((x, i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="col-3 m-b-10">
                                {x.relationUserId ? x.relationUserId.basicDetails.name : x.to_name}
                              </div>
                              <div className="col-2 m-b-10">
                                {
                                  x.relationDetails?.memberFrom && x.relationDetails?.memberFrom.toLowerCase() != currentUser.basicDetails.name.toLowerCase() ?
                                    <span id={`tooltip-${i}`}>
                                      {x?.relationDetails?.memberFrom}
                                      <InfoIcon />
                                      {"(" + relations.find((rel) => rel._id == x.relationDetails?.relation_id)[currentUser.basicDetails.motherTongue.mothertongue] + ")"}
                                      <Tooltip
                                        placement={"bottom"}
                                        isOpen={tooltip === i}
                                        target={`tooltip-${i}`}
                                        toggle={() => { if (tooltip == i) { setTooltip(null) } else { setTooltip(i) } }}
                                      >
                                        {x.memberFromRelations.map((item, key) => {
                                          let next = x.memberFromRelations.length - 1 > key
                                          return (
                                            <span key={key}>
                                              {relations.find((rel) => rel._id == item)[currentUser.basicDetails.motherTongue.mothertongue]}{next ? "=>" : ""}
                                            </span>
                                          )
                                        })}
                                      </Tooltip>
                                    </span>
                                    : <>
                                      <span id={`tooltip-${i}`}>
                                        {relations.find((rel) => rel._id == x.relationDetails.relation_id)[currentUser.basicDetails.motherTongue.mothertongue]}
                                      </span>
                                    </>
                                }
                              </div>
                              <div className="col-2 m-b-10">
                                {x.parentDetails ? x.parentDetails?.to_name : "-"}
                              </div>
                              <div className="col-2 m-b-10">
                                {x.spouseDetails ? x.spouseDetails?.to_name : "-"}
                              </div>
                              {/* <div className="col-2 m-b-10">
                                {x.relationDetails.side ? x.relationDetails.side : "-"}
                              </div> */}
                              <div className="col-2 m-b-10">
                                <div className="btn-group" style={{ gap: "3px" }}>
                                  {checkEditEnable(x) ? <Button className="btn btn-success" style={{ "padding": "5px 14px" }} onClick={() => { setModal(true); setEditMember(x) }}>Edit</Button> : <></>}
                                  {checkDeleteEnable(x) ? <Button className="btn btn-danger" style={{ "padding": "5px 14px" }} onClick={() => { setDeleteModal(true); setDeleteMember(x._id) }}>Delete</Button> : <></>}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {(familyMembers && tab == "other") &&
                        familyMembers.filter(x => x.relationDetails.side == "other").map((x, i) => {
                          return (
                            <div className="row" key={i}>
                              <div className="col-3 m-b-10">
                                {x.relationUserId ? x.relationUserId.basicDetails.name : x.to_name}
                              </div>
                              <div className="col-2 m-b-10">
                                {
                                  x.relationDetails?.memberFrom && x.relationDetails?.memberFrom.toLowerCase() != currentUser.basicDetails.name.toLowerCase() ?
                                    <span id={`tooltip-${i}`}>
                                      {x?.relationDetails?.memberFrom}
                                      <InfoIcon />
                                      {"(" + relations.find((rel) => rel._id == x.relationDetails?.relation_id)[currentUser.basicDetails.motherTongue.mothertongue] + ")"}
                                      <Tooltip
                                        placement={"bottom"}
                                        isOpen={tooltip === i}
                                        target={`tooltip-${i}`}
                                        toggle={() => { if (tooltip == i) { setTooltip(null) } else { setTooltip(i) } }}
                                      >
                                        {x.memberFromRelations.map((item, key) => {
                                          let next = x.memberFromRelations.length - 1 > key
                                          return (
                                            <span key={key}>
                                              {relations.find((rel) => rel._id == item)[currentUser.basicDetails.motherTongue.mothertongue]}{next ? "=>" : ""}
                                            </span>
                                          )
                                        })}
                                      </Tooltip>
                                    </span>
                                    : <>
                                      <span id={`tooltip-${i}`}>
                                        {relations.find((rel) => rel._id == x.relationDetails.relation_id)[currentUser.basicDetails.motherTongue.mothertongue]}
                                      </span>
                                    </>
                                }
                              </div>
                              <div className="col-2 m-b-10">
                                {x.parentDetails ? x.parentDetails?.to_name : "-"}
                              </div>
                              <div className="col-2 m-b-10">
                                {x.spouseDetails ? x.spouseDetails?.to_name : "-"}
                              </div>
                              {/* <div className="col-2 m-b-10">
                                {x.relationDetails.side ? x.relationDetails.side : "-"}
                              </div> */}
                              <div className="col-2 m-b-10">
                                <div className="btn-group" style={{ gap: "3px" }}>
                                  {checkEditEnable(x) ? <Button className="btn btn-success" style={{ "padding": "5px 14px" }} onClick={() => { setModal(true); setEditMember(x) }}>Edit</Button> : <></>}
                                  {checkDeleteEnable(x) ? <Button className="btn btn-danger" style={{ "padding": "5px 14px" }} onClick={() => { setDeleteModal(true); setDeleteMember(x._id) }}>Delete</Button> : <></>}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card bordernone col-sm-4" style={{ "height": "max-content" }}>
              <div className="card-body box-shadow05 m-t-20 align-self-center">
                <button className="btn btn-success" onClick={UpdateMemberRelation}>update Member</button>
              </div>
              <div className="card-body box-shadow05 m-t-20">
                <div className="col-md-12 col-xs-12 m-b-30 p-r-0 p-l-0 border-bottom1gray p-b-10 float-left">
                  <h6 className="float-left">
                    Family Tree View Requests
                  </h6>
                </div>
                <div className="clearfix"></div>
                <div id="customer-needs" className="nav-1 cus-prmview">
                  <div className="item">
                    <div className="col-md-12 col-xs-12 m-b-10 p-0">
                      <div className="row">
                        {treeView && treeView.length > 0 ? treeView.map((x, i) => (
                          <div key={i} className="random-prod cus-rendom-prod d-flex flex-row flex-wrap align-content-center align-items-center justify-content-between">
                            <div>
                              <div className="random-img">
                                <img alt="" src={`${CONFIG.REACT_APP_UPLOADS}/${x.requestFrom.profilePicture}`} height="55" width="55" className="img-circle float-left" style={{ border: "1px solid #dedede", objectFit: 'cover', padding: "2px" }} />
                              </div>
                              <div className="random-text">
                                <h5 className="title-1 no-margin">
                                  {" "}
                                  <Link to={`/viewProfile/${x.requestFrom._id}`}>
                                    <strong>{x.requestFrom.basicDetails.name}</strong>{" "}
                                  </Link>{" "}
                                  <div className="d-flex">
                                    <Button className="btn-success btn-sm" onClick={() => changeStatus(x._id, 'accepted')}>
                                      Accept
                                    </Button>
                                    <Button className="btn-danger btn-sm" onClick={() => changeStatus(x._id, 'rejected')}>
                                      Reject
                                    </Button>
                                  </div>
                                </h5>
                              </div>
                            </div>
                          </div>
                        )) : <div className="random-prod cus-rendom-prod d-flex flex-row flex-wrap align-content-center align-items-center justify-content-between">No Request Found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                {adsContent.right && <div className="card-body box-shadow05 m-t-20">
                  <div
                    className="ads-div"
                    dangerouslySetInnerHTML={{ __html: adsContent.right }}
                  />
                </div>}
              <div className="card-body box-shadow05 m-t-20">
                <div className="col-md-12 col-xs-12 m-b-30 p-r-0 p-l-0 border-bottom1gray p-b-10 float-left">
                  <h6 className="float-left">
                    Family Members Recommendation
                  </h6>
                </div>
                <div className="clearfix"></div>
                <div id="customer-needs" className="nav-1 cus-prmview">
                  <div className="item">
                    <div className="col-md-12 col-xs-12 m-b-10 p-0">
                      <div className="row">
                        {profiles && profiles.length > 0 ? profiles.map((x, i) => (
                          <div key={i} className="random-prod cus-rendom-prod d-flex flex-row flex-wrap align-content-center align-items-center justify-content-between">
                            <div>
                              <div className="random-img">
                                <img alt="" src={`${CONFIG.REACT_APP_UPLOADS}/${x.userDetails[0].profilePicture}`} height="55" width="55" className="img-circle float-left" style={{ border: "1px solid #dedede", objectFit: 'cover', padding: "2px" }} />
                              </div>
                              <div className="random-text">
                                <h5 className="title-1 no-margin">
                                  {" "}
                                  <Link to={`/viewProfile/${x.user_id}`}>
                                    <strong>{x.to_name}</strong>{" "}
                                  </Link>{" "}
                                </h5>
                              </div>
                            </div>
                          </div>
                        )) : <>Connect with more member and get recommended</>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {modal && <Modal
          isOpen={modal}
        >
          <ModalHeader toggle={() => { setModal(false); setEditMember(false) }}>Family Members</ModalHeader>
          <ModalBody className="m-2">
            <div style={{ display: "flex", marginTop: 10, gap: "10px" }}>
              <div style={{ width: "200px" }}>
                <Label>Name</Label>
              </div>
              {familyMembers.find(x => x.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112")._id != editMember.relationDetails.memberFromId && <div style={{ width: "150px" }}>
                <Label>Member From</Label>
              </div>}
              <div style={{ width: "150px" }}>
                <Label>Relation</Label>
              </div>
              <div style={{ width: "150px" }}>
                <Label>Parent</Label>
              </div>
              <div style={{ width: "150px" }}>
                <Label>Spouse</Label>
              </div>
            </div>
            <div style={{ display: "flex", gap: "2px" }}>
              <div style={{ width: "200px" }}>
                <input
                  required
                  type="text"
                  className="form-control"
                  name="name"
                  value={editMember.to_name}
                  placeholder="Enter Name"
                  onChange={(e) => setEditMember({ ...editMember, to_name: e.target.value })}
                  disabled={editMember.user_id}
                />
              </div>
              {familyMembers.find(x => x.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112")._id != editMember.relationDetails.memberFromId && <div style={{ width: "150px", marginLeft: 10 }}>
                <Input type="select" name="relation" value={editMember?.relationDetails?.memberFromId} onChange={(e) => setEditMember({
                  ...editMember,
                  relationDetails: {
                    ...editMember.relationDetails,
                    memberFromId: e.target.value
                  }
                })}
                  disabled={editMember.user_id == currentUser._id}>
                  {
                    familyMembers.filter(x => x._id != editMember._id).map((x) => {
                      return (
                        <option value={x._id}>{x.to_name}</option>
                      )
                    })
                  }
                </Input>
              </div>}
              <div style={{ width: "150px", marginLeft: 10 }}>
                <Input type="select" name="relation" value={editMember?.relationDetails?.relation_id} onChange={(e) => setEditMember({
                  ...editMember,
                  relationDetails: {
                    ...editMember.relationDetails,
                    relation_id: e.target.value
                  }
                })}
                  disabled={editMember.user_id == currentUser._id}>
                  <option value="">Select Relation</option>
                  {relationOption
                    .map((x, i) => {
                      return (
                        <option
                          value={x.no}
                          key={i}
                        >
                          {x.option}
                        </option>
                      );
                    })}
                </Input>
              </div>
              <div style={{ width: "150px", marginLeft: 10 }}>
                <Input
                  type="select"
                  name="parent"
                  disabled={(editMember.user_id || editMember.relationDetails?.memberFromId != familyMembers.find(x => x.relationDetails.relation_id == "65dc7cb2d189e84ba2c23112")._id) ? true : familyMembers?.filter((x) => CAN_BE_PARENTS[editMember?.relationDetails?.relation_id]?.includes(String(x?.relationDetails?.relation_id))).length == 0}
                  onChange={(e) =>
                    setEditMember({
                      ...editMember,
                      parentDetails: {
                        ...editMember.parentDetails,
                        to_name: e.target.value,
                        _id: familyMembers.find((x) => x.to_name == e.target.value)._id
                      }
                    })
                  }
                  value={editMember?.parentDetails?.to_name}
                >
                  <option>Select Parent</option>
                  {
                    (CAN_BE_PARENTS[editMember?.relationDetails?.relation_id]) &&
                      CAN_BE_PARENTS[editMember?.relationDetails?.relation_id]?.length != 0 ?
                      familyMembers.filter((x) => CAN_BE_PARENTS[editMember?.relationDetails?.relation_id].includes(String(x.relationDetails?.relation_id)))
                        ?.map((x, i) => <option value={x?.to_name} disabled={x.to_name ? false : true} key={i}>{x?.to_name}</option>)
                      : null
                  }
                </Input>
              </div>
              <div style={{ width: "150px", marginLeft: 10 }}>
                <Input
                  type="select"
                  name="spouse"
                  value={editMember?.spouseDetails?.to_name}
                  disabled={editMember.user_id ? true : familyMembers.filter((x) => CAN_BE_SPOUSE[editMember?.relationDetails?.relation_id]?.includes(String(x?.relationDetails?.relation_id))).length == 0}
                  onChange={(e) =>
                    setEditMember({
                      ...editMember,
                      spouseDetails: {
                        ...editMember.spouseDetails,
                        to_name: e.target.value,
                        _id: familyMembers.find((x) => x.to_name == e.target.value)._id
                      }
                    })
                  }
                >
                  <option>Select Spouse</option>
                  {
                    CAN_BE_SPOUSE[editMember?.relationDetails?.relation_id] &&
                      CAN_BE_SPOUSE[editMember?.relationDetails?.relation_id]?.length != 0 ?
                      familyMembers.filter((x) => CAN_BE_SPOUSE[editMember?.relationDetails?.relation_id].includes(String(x.relationDetails?.relation_id)))
                        ?.map((x, i) => <option value={x?.to_name} disabled={x.to_name ? false : true} key={i}>{x?.to_name}</option>)
                      : null
                  }
                </Input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" className=" btn btn-success" onClick={() => { addnewMember() }} disabled={loading}>
              Update
            </Button>
            <Button type="button" className=" btn btn-danger" onClick={() => setModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>}
      </div>
      <div>
        <Modal isOpen={deleteModal}>
          <ModalHeader toggle={() => setDeleteModal(false)}>Delete Family Members</ModalHeader>
          <ModalBody>
            <p>Are You Sure?</p>
          </ModalBody>
          <ModalFooter>
            <Button type="button" className=" btn btn-success" onClick={() => { deleteUser(); setDeleteModal(false) }}>
              Delete
            </Button>
            <Button type="button" className=" btn btn-danger" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
}



const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.user.currentUser,
  ads: state.data.ads,
  relations: state.data.relations,
  loading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  setUserData: (user) => dispatch(setUserData(user)),
  startLoading: () => dispatch(loadingActions.loadingStart()),
  endLoading: () => dispatch(loadingActions.loadingEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMemberList);

