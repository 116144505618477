import authConstant from "../constants/auth_constant";

const authState = {
  currentUser: {},
  loggedIn: false,
  token: null,
  userId: null,
  userRole: null,
  userData: null,
  message: null,
};

function auth(state = authState, action) {
  switch (action.type) {
    case authConstant.LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        userRole: action.payload.userRole,
        userData: action.payload.userData,
        token: action.payload.token,
        currentUser: action.payload.userData,
      };
    case authConstant.LOGIN_FAILURE:
      return {
        ...state,
        currentUser: {},
        token: null,
        loggedIn: false,
        message: action.payload,
      };
    case authConstant.LOGIN_REQUEST:
      return {
        ...state,
        token: null,
        message: action.payload,
      };
    case  authConstant.UPDATE_USER:
      return {
        ...state,
        userData: action.payload
      }
   case  authConstant.SET_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.payload.name]: action.payload.data
        }
      }
    case authConstant.LOGOUT:
      return {
        ...state,
        currentUser: {},
        token: null,
        message: null,
        loggedIn: false,
      };
    default:
      return state;
  }
}

export default auth;
