/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import footerLogo from "../assets/images/footer-logo.png";
import useScrollTop from './helper/useScrollTop'

const Footer = () => {
  const isTopOfPage = useScrollTop();
  return (
    <React.Fragment>

      {/* <div className="footer-top-section bg-cyan-blue pt-100 pb-90">
        <div className="container">
          <div className="row">
            <div className="footer-widget text-left col-lg-6 col-md-12 col-12 mb-30">
              <h1 className="title">Get In Touch</h1>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
                into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
                release of Letraset sheets containing Lorem Ipsum passages,
          </p>
            </div>
            <div className="footer-widget text-center col-lg-6 col-md-12 col-12">
              <div className="footer-about">
                <div className="row">
                  <form className="text-center form-footer">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 float-left">
                      <input
                        type="text"
                        id="defaultContactFormName"
                        className="form-control mb-4"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 float-left">
                      <input
                        type="text"
                        id="defaultContactFormEmail"
                        className="form-control mb-4"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 float-left">
                      <input
                        type="email"
                        id="defaultContactFormEmail"
                        className="form-control mb-4"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 float-left">
                      <textarea
                        type="Message"
                        id="defaultContactFormEmail"
                        className="form-control mb-4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12 float-left">
                      <button className="btn btn-info btn-block" type="submit">SUBMIT</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="footer-bottom-section">
        <div className="container">
          <div className="row">
            <div className="footer-widget text-left col-lg-6 col-md-6 col-sm-12 mb-10">
              <img src={footerLogo} alt="Footer Logo" style={{ objectFit: 'cover' }} />
            </div>

            <div className="footer-copyright text-right col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="footer-social">
                <a href="https://www.facebook.com/familytreesin"><i className="fa fa-facebook"></i></a>
                <a href="https://x.com/familytreein"><i className="fa fa-twitter"></i></a>
                <a ><i className="fa fa-instagram"></i></a>
                <a ><i className="fa fa-pinterest-p"></i></a>
                <a href="https://www.linkedin.com/company/familytreein/"><i className="fa fa-linkedin"></i></a>
              </div>
              <p>copyright © 2018-{new Date().getFullYear()} familytree. All rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
      {isTopOfPage ? <></> : <a id="scrollUp" href="#top" style={{ position: "fixed", zIndex: "2147483647", display: "block" }}><i className="fa fa-angle-up"></i></a>}
    </React.Fragment>
  );
};

export default Footer;
