import member_requests_constant from "../constants/member_requests_constant";

export const updateMemberRequestAction = (payload) => {
  return {
    type: member_requests_constant.SET_REQUESTS,
    payload: payload,
  };
};

export default { updateMemberRequestAction };
