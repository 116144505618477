import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import navActions from "../redux/actions/navigation_action";
import { toast } from "react-toastify";

const AlerComponent = (props) => {
  const { isOpen, message, toggleAlert } = props;

  return message && isOpen && toast(message, { position: 'top-right', theme: 'dark' })
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  message: state.nav.alert.message,
  isOpen: state.nav.alert.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAlert: (props) => dispatch(navActions.fetch_clear(props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlerComponent);
