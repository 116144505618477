import { createAction } from "@reduxjs/toolkit";
import auth_constant from "../constants/auth_constant";
import authConstant from "../constants/auth_constant";
import navigation_constant from "../constants/navigation_constant";
import { login, adminLogin, checkLogin, checkAdminLogin } from "../services/authService";
import user_constant from "../constants/user_constant";

export const loginAction = (user, history) => {
  //   const request = props => createAction(navigation_constant.FECTCH_REQUEST)(props);
  const success = (props) => createAction(authConstant.LOGIN_SUCCESS)(props);
  const failure = (props) => createAction(navigation_constant.FETCH_FAILURE)(props);
  const successToast = (props) => createAction(navigation_constant.FETCH_SUCCESS)(props);
  const clearToast = (props) => createAction(navigation_constant.FETCH_CLEAR)(props);
  return (dispatch) => {
    // dispatch(request());
    login(user).then(
      (data) => {
        if (data.code === 5) {
          localStorage.setItem(
            "family_token",
            JSON.stringify({ token: data.token, userId: data.user._id, userRole: data.userRole, setTime: new Date().getTime(), userData: data.user })
          );
          dispatch(
            success({
              userId: data.user._id,
              userRole: data.userRole,
              token: data.token,
              userData: data.user,
            })
          );
          dispatch(successToast({ message: "Login Successfully", isOpen: true }));
          setTimeout(() => {
            dispatch(clearToast())
          }, 10)
          setTimeout(() => {
            history.push("/dashboard");
          }, 500)
        } else {
          dispatch(failure({ message: data }));
          // dispatch(clearToast());
          // history.push("/");
        }
      },
      (error) => {
        dispatch(failure({ message: error }));
        // dispatch(clearToast());
        // history.push("/");
      }
    );
  };
};

export const checkLoginAction = (history) => {
  const request = (props) => createAction(navigation_constant.FETCH_REQUEST)(props);
  const success = (props) => createAction(authConstant.LOGIN_SUCCESS)(props);
  const setUser = (props) => ({
    type: user_constant.SET_USERDATA,
    currentUser: props.currentUser,
  });
  // const failure = (props) => createAction(navigation_constant.FETCH_FAILURE)(props);
  return async (dispatch) => {
    dispatch(request());
    if (localStorage.getItem("family_token")) {
      let family_token = JSON.parse(localStorage.getItem("family_token"));
      if (new Date().getTime() - family_token.setTime <= 2 * 60 * 60 * 1000) {
        // request();
        //  checkLogin(family_token.token).then(data => {
        let data
        if (family_token.userRole == 1) {
          data = await checkAdminLogin(family_token.token).then(data => data);
        } else {
          data = await checkLogin(family_token.token).then(data => data);
        }

        dispatch(
          success({
            userId: family_token.userId,
            userRole: family_token.userRole,
            token: family_token.token,
            userData: data.user
            // userData: family_token.userData,
          })
        );
        dispatch(
          setUser({currentUser:data.user})
        );
        // if (family_token.userRole == 2) {
        //   history.push("/dashboard");
        // }
        // if (family_token.userRole == 1) {
        //   history.push("/admin");
        // }
        // });

      } else {
        family_token.userRole === 1 ? history.push("/adminlogin") : history.push("/login");
        localStorage.removeItem("family_token");
      }
    }
  };
};

export const logoutAction = (history, userRole) => {
  const logout = (props) => createAction(authConstant.LOGOUT)(props);
  return (dispatch) => {
    logout();
    dispatch(logout());
    localStorage.removeItem("family_token");
    let adminToken = localStorage.getItem("admin_token")
    if (adminToken) {
      localStorage.setItem("family_token", adminToken)
      localStorage.removeItem("admin_token")
      window.location.assign("/admin");
      return
    }
    if (userRole === 1) window.location = "/adminlogin";
    else window.location = "/";
  };
};

export const adminLoginAction = (user, history) => {
  //   const request = props => createAction(navigation_constant.FECTCH_REQUEST)(props);
  const success = (props) => createAction(authConstant.LOGIN_SUCCESS)(props);
  const failure = (props) => createAction(navigation_constant.FETCH_FAILURE)(props);
  return (dispatch) => {
    // dispatch(request());
    adminLogin(user).then(
      (data) => {
        if (data.user) {
          localStorage.setItem(
            "family_token",
            JSON.stringify({ token: data.token, userId: data.user._id, userRole: data.userRole, setTime: new Date().getTime() })
          );
          dispatch(
            success({
              userId: data.user._id,
              userRole: data.userRole,
              token: data.token,
              userData: data.user,
            })
          );
          history.push("/admin");
        } else {
          dispatch(failure({ message: data }));
          // history.push("/");
        }
      },
      (error) => {
        dispatch(failure({ message: error }));
        // history.push("/");
      }
    );
  };
};


export const updateUserData = (user) => {
  return (dispatch) => {

    let family_token = JSON.parse(localStorage.getItem("family_token"));
    localStorage.setItem(
      "family_token",
      JSON.stringify({
        userId: family_token.userId,
        userRole: family_token.userRole,
        token: family_token.token,
        userData: user,
        setTime: family_token.setTime
      })
    );

    dispatch({
      type: auth_constant.UPDATE_USER,
      payload: user,
    });
  }
};

export const updateUser = data => {
  return (dispatch) => {

    let family_token = JSON.parse(localStorage.getItem("family_token"));
    let userData = family_token.userData;
    userData[data.name] = data.value;

    localStorage.setItem(
      "family_token",
      JSON.stringify({
        userId: family_token.userId,
        userRole: family_token.userRole,
        token: family_token.token,
        userData: userData,
        setTime: family_token.setTime
      })
    );

    dispatch({
      type: auth_constant.SET_USER,
      payload: data,
    });
  }
}

export default { loginAction, checkLoginAction, adminLoginAction, updateUserData, updateUser };
